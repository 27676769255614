import React, { useState, useCallback } from 'react';
import { Switch } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Dropzone from 'react-dropzone';

const RegistroHospital = ({ currentPage }) => {
  const [newHospital, setNewHospital] = useState(initialHospitalState());
  const [latLng, setLatLng] = useState({ lat: 19.432608, lng: -99.133209 });

  function initialHospitalState() {
    return {
      nombreHos: '',
      telefonoHos: '',
      correoHos: '',
      direccionHos: '',
      longitudHos: '',
      latitudHos: '',
      imagenHos: '',
      especialHos: 0,
      statusHos: 0,
      informacionHos: '',
    };
  }

  const handleNewInputChange = (e) => {
    const { name, value } = e.target;
    setNewHospital((prev) => ({ ...prev, [name]: value }));
  };

  const altaHospital = async () => {
    if (
      !newHospital.nombreHos.trim() ||
      !newHospital.telefonoHos.trim() ||
      !newHospital.correoHos.trim() ||
      !newHospital.direccionHos.trim() ||
      !latLng.lat ||
      !latLng.lng
    ) {
      toast.error('Por favor, completa todos los campos obligatorios.');
      return;
    }
    try {
      const hospitalWithLocation = { ...newHospital, latitudHos: latLng.lat, longitudHos: latLng.lng };
      await fetch('https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Hospital/api/Alta', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(hospitalWithLocation),
      });
      toast.success('Hospital agregado con éxito!');
      setNewHospital(initialHospitalState());
      setLatLng({ lat: 19.432608, lng: -99.133209 });
    } catch (err) {
      toast.error('Error al dar de alta el hospital.');
    }
  };

  const handleImageUpload = useCallback(async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/Uploadhospital/simpleupload', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error('Error al subir la imagen');

      const data = await response.json();
      setNewHospital((prev) => ({ ...prev, imagenHos: data.url.toString() }));
    } catch (error) {
      toast.error('Error al subir la imagen.');
    }
  }, []);

  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => setLatLng({ lat: e.latlng.lat, lng: e.latlng.lng }),
    });
    return null;
  };

  return (
    <div
      className={`page ${currentPage === 2 ? 'visible' : ''}`}
      data-page="2"
      style={{
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '120px',
        paddingBottom: '120px',
      }}
    >
      <h2
        style={{
          fontSize: 'clamp(2.5rem, 4vw, 3.5rem)',
          fontWeight: '800',
          marginBottom: '1rem',
          background: 'linear-gradient(90deg, #fff 0%, #7fd3ed 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Registrar mi hospital
      </h2>
      <span style={{
            fontSize: '1.1rem',
            fontWeight: '500',
            color: '#7fd3ed',
            letterSpacing: '2px',
            marginBottom: '1rem',
            display: 'block'
          }}>
            FORMA PARTE DE LA TRANSFORMACION DE LA SALUD
          </span>
      <form
        style={{
          ...formContainerStyle,
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
        onSubmit={(e) => {
          e.preventDefault();
          altaHospital();
        }}
      >
        <label style={labelStyle}>
          Nombre del hospital
          <input type="text" name="nombreHos" style={inputStyle} value={newHospital.nombreHos} onChange={handleNewInputChange} />
        </label>

        <label style={labelStyle}>
          Teléfono
          <input type="tel" name="telefonoHos" style={inputStyle} value={newHospital.telefonoHos} onChange={handleNewInputChange} />
        </label>

        <label style={labelStyle}>
          Correo electrónico
          <input type="email" name="correoHos" style={inputStyle} value={newHospital.correoHos} onChange={handleNewInputChange} />
        </label>

        <label style={labelStyle}>
          Dirección
          <input type="text" name="direccionHos" style={inputStyle} value={newHospital.direccionHos} onChange={handleNewInputChange} />
        </label>

        <label style={labelStyle}>
          Información adicional
          <textarea name="informacionHos" className="large-input" rows="4" value={newHospital.informacionHos} onChange={handleNewInputChange}></textarea>
        </label>

        <label style={labelStyle}>
          Imagen del hospital
          <Dropzone onDrop={(acceptedFiles) => handleImageUpload(acceptedFiles[0])}>
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps()}
                style={{
                  border: '2px dashed #ccc',
                  borderRadius: '5px',
                  padding: '1rem',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <input {...getInputProps()} />
                {newHospital.imagenHos ? (
                  <img src={newHospital.imagenHos} alt="Hospital" style={{ maxWidth: '100%', height: 'auto' }} />
                ) : (
                  <p>Arrastra una imagen aquí o haz clic para subirla.</p>
                )}
              </div>
            )}
          </Dropzone>
        </label>

        <label style={labelStyle}>Hospital de Especialidad:</label>
          <Switch
            checked={newHospital.especialHos === 1}
            onChange={(e) =>
              handleNewInputChange({
                target: {
                  name: "especialHos",
                  value: e.target.checked ? 1 : 0,
                },
              })
            }
          />

        <label style={labelStyle}>
          Selecciona la ubicación
          <div style={{ height: '300px', width: '100%', marginTop: '1rem' }}>
            <MapContainer center={latLng} zoom={13} style={{ height: '100%', borderRadius: '5px' }}>
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker position={latLng} />
              <MapClickHandler />
            </MapContainer>
          </div>
        </label>

        <button type="submit" style={buttonStyle}>
          Registrar hospital
        </button>
      </form>
      <ToastContainer autoClose={2000} closeButton={false} closeOnClick pauseOnHover draggable className="custom-toast" />
    </div>
  );
};

const formContainerStyle = {
  backgroundColor: 'white',
  padding: '30px',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  width: '500px',
};

const inputStyle = {
  width: '100%',
  padding: '8px',
  margin: '10px 0',
  border: '1px solid #ccc',
  borderRadius: '5px',
};

const labelStyle = {
  fontSize: '14px',
  marginBottom: '5px',
  display: 'block',
  fontWeight: 'bold',
};

const buttonStyle = {
  backgroundColor: '#007bff',
  color: 'white',
  padding: '10px 20px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  width: '100%',
  marginTop: '20px',
};

export default RegistroHospital;