import { useEffect, useState } from "react";
import './userstyles.css';

const Pagos = () => {
  const [pagos, setPagos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const fetchPagos = async () => {
    try {
      const response = await fetch("https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/PagosDoc/api/Todos", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Error al obtener los pagos');
      }

      const data = await response.json();
      setPagos(data);
      setLoading(false);
    } catch (err) {
      setError("Error al cargar los datos de pagos");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPagos();
  },);

  if (loading) return <div>Cargando pagos...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <h2>Pagos Pendientes</h2>
      <div className="table-container">
        {pagos.length > 0 ? (
          <table className="styled-table">
            <thead>
              <tr>
                <th>Referencia</th>
                <th>Monto</th>
                <th>Fecha de Pago</th>
                <th>Pagado al Doctor</th>
              </tr>
            </thead>
            <tbody>
              {pagos.map((pago) => (
                <tr key={pago.idpago}>
                  <td>{pago.referencia}</td>
                  <td>${pago.monto}</td>
                  <td>{new Date(pago.fecha_pago).toLocaleDateString()}</td>
                  <td>{pago.pago_doctor ? "Sí" : "No"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div>No hay pagos pendientes</div>
        )}
      </div>
    </div>
  );
};

export default Pagos;
