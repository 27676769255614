import React, { useEffect, useState } from 'react';
import jsPDF from 'jspdf';

function PdfDoc({ cita }) {
  const [datosReceta, setDatosReceta] = useState(null); 
  const [loading, setLoading] = useState(true); 

  // Llamada a la API para obtener los datos de la receta
  useEffect(() => {
    const fetchReceta = async () => {
      try {
        const response = await fetch(
          `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Receta/api/Search/${cita.idCita}`,
          { method: 'GET' }
        );

        if (response.ok) {
          const data = await response.json();
          setDatosReceta(data);
        } else {
          console.error('Error al obtener los datos de la receta:', response.statusText);
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReceta();
  }, [cita.idCita]);

  // Función para generar el PDF con los datos de la receta
  const generarPDF = () => {
    if (!datosReceta) return;

    const doc = new jsPDF();
    let lineHeight = 10;
    const maxHeight = 280;
    let currentHeight = 20;

    // Detalles del médico
    doc.setFont('Helvetica', 'bold');
    doc.setFontSize(12);
    doc.setTextColor(21, 59, 118);
    doc.text('Doctor:', 20, currentHeight);
    doc.setFont('Helvetica', 'normal');
    doc.text(`${datosReceta.nombreUs} ${datosReceta.apellidoUs}`, 40, currentHeight);
    currentHeight += 5;

    doc.setFont('Helvetica', 'bold');
    doc.text('Cédula Profesional:', 20, currentHeight);
    doc.setFont('Helvetica', 'normal');
    doc.text(`${datosReceta.cedDoc}`, 65, currentHeight);
    currentHeight += lineHeight;

    // Línea encima del título
    doc.setDrawColor(21, 59, 118);
    doc.setLineWidth(0.5);
    doc.line(20, currentHeight, 190, currentHeight);
    currentHeight += lineHeight;

    // Título del documento
    doc.setFontSize(26);
    doc.setFont('Helvetica', 'bold');
    doc.setTextColor(21, 59, 118);
    doc.text('Receta Médica', 105, currentHeight, { align: 'center' });
    currentHeight += lineHeight * 1.5;

    // Información del paciente
    doc.setFontSize(12);
    doc.setTextColor(0, 0, 0);

    // Paciente en negrita
    doc.setFont('Helvetica', 'bold');
    doc.text('Paciente:', 20, currentHeight);

    // Nombre del paciente
    doc.setFont('Helvetica', 'normal');
    doc.text(`${datosReceta.nombrePac} ${datosReceta.apellidoPac}`, 40, currentHeight);

    // Edad en negrita
    doc.setFont('Helvetica', 'bold');
    doc.text('Edad:', 125, currentHeight);

    // Valor de la edad
    doc.setFont('Helvetica', 'normal');
    doc.text(`${calcularEdad(datosReceta.fechaNacimientoPac)} años`, 140, currentHeight);
    currentHeight += lineHeight;

    // Altura en negrita
    doc.setFont('Helvetica', 'bold');
    doc.text('Altura:', 20, currentHeight);

    // Valor de la altura
    doc.setFont('Helvetica', 'normal');
    doc.text(`${datosReceta.alturaPac} cm`, 35, currentHeight);

    // Peso en negrita
    doc.setFont('Helvetica', 'bold');
    doc.text('Peso:', 125, currentHeight);

    // Valor del peso
    doc.setFont('Helvetica', 'normal');
    doc.text(`${datosReceta.pesoPac} kg`, 140, currentHeight);
    currentHeight += 15;


    if (datosReceta.diagnosticoRec) {
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.setFont('Helvetica', 'bold');
        doc.text('Diagnóstico:', 20, currentHeight);
        doc.setFont('Helvetica', 'normal');
        doc.text(`${datosReceta.diagnosticoRec}`, 48, currentHeight);
        currentHeight += 10;
    }

    if (datosReceta.notaRec) {
        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.setFont('Helvetica', 'bold');
        doc.text('Notas:', 20, currentHeight);
        doc.setFont('Helvetica', 'normal');
        doc.text(`${datosReceta.notaRec}`, 35, currentHeight);
        currentHeight += 10;
    }

    // Título de Medicamentos
    currentHeight += 10;
    doc.setFontSize(20);
    doc.setFont('Helvetica', 'bold');
    doc.setTextColor(21, 59, 118);
    doc.text('Medicamentos', 105, currentHeight, { align: 'center' });
    currentHeight += 10;

    lineHeight = 5;

    // Detalle de la receta: Mapear los medicamentos separados por comas
    const medicamentos = datosReceta.medicamentosRec.split(',');
    const intervalos = datosReceta.intervalosRec.split(',');
    const duraciones = datosReceta.duracionRec.split(',');

    medicamentos.forEach((medicamento, index) => {
        const intervalo = intervalos[index] || '';
        const duracion = duraciones[index] || '';

        if (currentHeight > maxHeight) {
            doc.addPage();
            currentHeight = 20;
        }

        doc.setFontSize(12);
        doc.setTextColor(0, 0, 0);
        doc.setFont('Helvetica', 'bold');
        doc.text('Medicamento: ', 20, currentHeight);
        doc.setFont('Helvetica', 'normal');
        doc.text(`${medicamento.trim()}`, 50, currentHeight);
        currentHeight += lineHeight;

        doc.setFont('Helvetica', 'bold');
        doc.text('Tomar cada: ', 20, currentHeight);
        doc.setFont('Helvetica', 'normal');
        doc.text(`${intervalo.trim()} horas`, 47, currentHeight);
        currentHeight += lineHeight;

        doc.setFont('Helvetica', 'bold');
        doc.text('Durante: ', 20, currentHeight);
        doc.setFont('Helvetica', 'normal');
        doc.text(`${duracion.trim()} días`, 40, currentHeight);
        currentHeight += lineHeight * 2;
    });
    
    // Insertar firma del doctor
    if (datosReceta.firmaDoc) {
      // Quitar el prefijo 'data:image/png;base64,' si existe
      datosReceta.firmaDoc = datosReceta.firmaDoc.replace("data:image/png;base64,", "");
  
      currentHeight += 60;
  
      // Ajustar la posición para centrar la imagen
      const imgWidth = 50; 
      const xPosition = (doc.internal.pageSize.width - imgWidth) / 2; 
      doc.addImage(datosReceta.firmaDoc, 'JPEG', xPosition, currentHeight, imgWidth, 25); 
      currentHeight += 20;
  
      // Línea debajo de la firma
      doc.setDrawColor(0, 0, 0);
      doc.setLineWidth(0.5);
      doc.line(xPosition, currentHeight, xPosition + 40, currentHeight); // Mantener la misma posición horizontal
      currentHeight += 5;
  
      // Texto "Firma" debajo de la línea
      doc.setFontSize(12);
      doc.setFont('Helvetica', 'normal');
      const textXPosition = (doc.internal.pageSize.width - doc.getStringUnitWidth('Firma') * doc.internal.getFontSize()) / 2; // Centrar el texto
      doc.text('Firma', textXPosition + 5, currentHeight);
    }
  

    //const nombreArchivo = `Receta_${datosReceta.nombrePac}_${datosReceta.apellidoPac}.pdf`;
    //doc.save(nombreArchivo);
    doc.output('dataurlnewwindow'); //visualizar sin descargar util tmb
  };

  // Función auxiliar para calcular la edad
  const calcularEdad = (fechaNacimiento) => {
    const hoy = new Date();
    const nacimiento = new Date(fechaNacimiento);
    let edad = hoy.getFullYear() - nacimiento.getFullYear();
    const mes = hoy.getMonth() - nacimiento.getMonth();
    if (mes < 0 || (mes === 0 && hoy.getDate() < nacimiento.getDate())) {
      edad--;
    }
    return edad;
  };

  if (loading) {
    return <p>Cargando datos de la receta...</p>;
  }

  return (
    <div>
      <button onClick={generarPDF}>Mostrar PDF</button>
    </div>
  );
}

export default PdfDoc;