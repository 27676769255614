import React, { useEffect, useState } from 'react';
import '../App.css';
import "../estilos/disponibles.css";
import HeaderDoc from './HeaderDoc';

function formFecha(fechaString) {
    const opciones = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(fechaString).toLocaleDateString(undefined, opciones);
  }
  
  function formHora(horaString) {
    const opciones = { hour: '2-digit', minute: '2-digit' };
    return new Date(horaString).toLocaleTimeString(undefined, opciones);
  }

function Mycitas() {
  const [id, setId] = useState(null); // Estado para almacenar la ID del doctor
  const [citas, setCitas] = useState([]); // Estado para almacenar las citas
  const usr = localStorage.getItem('usuario');

  // Efecto para obtener la ID del doctor
  useEffect(() => {
    const obtenerId = async () => {
      try {
        const response = await fetch(
          `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/List/${usr}`,
          { method: 'GET' }
        );

        if (response.ok) {
          const data = await response.json();
          setId(data[0].idDoc); // Asegúrate de que idDoc sea la propiedad correcta
        } else {
          console.error('Error al obtener el ID:', response.statusText);
        }
      } catch (error) {
        console.error('Error en la solicitud:', error);
      }
    };

    obtenerId();
  }, [usr]);

  // Efecto para obtener las citas del doctor usando la ID
  useEffect(() => {
    const obtenerCitas = async () => {
      if (id) { // Verificamos que la id esté disponible antes de hacer la solicitud
        try {
          const response = await fetch(
            `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Cita/api/List/citas_doctor/${id}`,
            { method: 'GET' }
          );

          if (response.ok) {
            const data = await response.json();
            // Filtrar las citas para mostrar solo las de statusCita = 1
            const citasFiltradas = data.filter(cita => cita.statusCita === 1);
            setCitas(citasFiltradas); // Almacenamos las citas filtradas en el estado
          } else {
            console.error('Error al obtener las citas:', response.statusText);
          }
        } catch (error) {
          console.error('Error en la solicitud:', error);
        }
      }
    };

    obtenerCitas();
  }, [id]); // Este efecto se ejecuta cada vez que la ID cambie

  return (
    <div className="fondo">
      <HeaderDoc/>
        <h2 className="titulos">Consultas Asignadas</h2>
      <div className="contenedor-cartas">
      {citas.length > 0 ? (
        citas.map((cita) => (
            <div key={cita.idCita} className="carta-cita">
            <div className="carta-contenido">
                <p><strong>Fecha:</strong> {formFecha(cita.fechaCita)}</p>
                <p><strong>Hora:</strong> {formHora(cita.horaCita)}</p>

                {cita.tipoCita === 1 && <p><strong>Tipo de Cita:</strong> Presencial</p>}
                {cita.tipoCita === 2 && <p><strong>Tipo de Cita:</strong> En línea</p>}
                {cita.especialidadCita !== 0 && (
                <p><strong>Especialidad:</strong> {cita.especialidadCita}</p>
                )}

                <p><strong>Estado:</strong> {cita.citaPagada ? 'Pagada' : 'No pagada'}</p>
            </div>
            </div>
        ))
        ) : (
          <p>No hay citas disponibles.</p>
        )}
      </div>
    </div>
  );
}

export default Mycitas;
