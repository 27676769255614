import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Reportes = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Total Registros por Día',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
      },
      {
        label: 'Pacientes',
        data: [],
        backgroundColor: 'rgba(54, 162, 235, 0.6)',
        borderColor: 'rgba(54, 162, 235, 1)',
        borderWidth: 1,
      },
      {
        label: 'Doctores',
        data: [],
        backgroundColor: 'rgba(255, 99, 132, 0.6)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
      {
        label: 'Administradores',
        data: [],
        backgroundColor: 'rgba(255, 206, 86, 0.6)',
        borderColor: 'rgba(255, 206, 86, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [totalUsuarios, setTotalUsuarios] = useState(0);
  const [usuariosPorTipo, setUsuariosPorTipo] = useState({});
  const [doctoresPerfilCompleto, setDoctoresPerfilCompleto] = useState(0);
  const [pacientesPorUsuarioPromedio, setPacientesPorUsuarioPromedio] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseUsuarios = await fetch('https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/QR/api/Todos');
        const dataUsuarios = await responseUsuarios.json();
        
        const responseDoctores = await fetch('https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/Todos');
        const dataDoctores = await responseDoctores.json();

        const responsePacientes = await fetch('https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Paciente/api/Todos');
        const fetchedDataPacientes = await responsePacientes.json();

        setTotalUsuarios(dataUsuarios.length);
        const countsByType = dataUsuarios.reduce((acc, user) => {
          const userType = user.tipoUs || 'Sin especificar';
          acc[userType] = (acc[userType] || 0) + 1;
          return acc;
        }, {});
        setUsuariosPorTipo(countsByType);

        const doctoresConPerfilCompleto = dataDoctores.filter(doc => doc.validacionDoc).length;
        setDoctoresPerfilCompleto(doctoresConPerfilCompleto);

        const uniqueIds = new Set(fetchedDataPacientes.map(paciente => paciente.idUs));
        const promedioPacientesPorUsuario = fetchedDataPacientes.length / uniqueIds.size;
        setPacientesPorUsuarioPromedio(promedioPacientesPorUsuario);

        const registrationCounts = dataUsuarios
          .filter(user => user.fechaRegistro)
          .reduce((acc, user) => {
            const date = new Date(user.fechaRegistro).toLocaleDateString('es-MX');
            const userType = user.tipoUs;

            if (!acc[date]) {
              acc[date] = { total: 0, tipo1: 0, tipo2: 0, tipo3: 0 };
            }

            acc[date].total += 1;
            acc[date][`tipo${userType}`] += 1;
            return acc;
          }, {});

        const sortedDates = Object.keys(registrationCounts).sort();
        const sortedCounts = sortedDates.map(date => registrationCounts[date].total);
        const tipo1Counts = sortedDates.map(date => registrationCounts[date].tipo1);
        const tipo2Counts = sortedDates.map(date => registrationCounts[date].tipo2);
        const tipo3Counts = sortedDates.map(date => registrationCounts[date].tipo3);

        setChartData({
          labels: sortedDates,
          datasets: [
            {
              label: 'Total Registros por Día',
              data: sortedCounts,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
            {
              label: 'Pacientes',
              data: tipo1Counts,
              backgroundColor: 'rgba(54, 162, 235, 0.6)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1,
            },
            {
              label: 'Doctores',
              data: tipo2Counts,
              backgroundColor: 'rgba(255, 99, 132, 0.6)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
            },
            {
              label: 'Administradores',
              data: tipo3Counts,
              backgroundColor: 'rgba(255, 206, 86, 0.6)',
              borderColor: 'rgba(255, 206, 86, 1)',
              borderWidth: 1,
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h2>Gráfico de Registros de Usuarios por Día</h2>
      {chartData.labels.length > 0 ? (
        <Bar data={chartData} options={{ responsive: true }} />
      ) : (
        <p>Cargando datos...</p>
      )}

      <h3>Resumen de Usuarios</h3>
      <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
        <div style={{ padding: '20px', backgroundColor: '#f0f0f0', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
          <h4>Total de Usuarios</h4>
          <p>{totalUsuarios}</p>
        </div>
        {Object.keys(usuariosPorTipo).map((tipoUs) => (
          <div key={tipoUs} style={{ padding: '20px', backgroundColor: '#f0f0f0', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <h4>Usuarios de tipo: {tipoUs === '1' ? 'Paciente' : tipoUs === '2' ? 'Doctor' : 'Administrador'}</h4>
            <p>{usuariosPorTipo[tipoUs]}</p>
          </div>
        ))}
      </div>
      <h3 style={{ marginTop: '40px' }}>Estadísticas de Doctores y Pacientes</h3>
      <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
        <div style={{ padding: '20px', backgroundColor: '#e3f2fd', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
          <h4>Doctores Registrados</h4>
          <p>{usuariosPorTipo['2'] || 0}</p>
        </div>
        <div style={{ padding: '20px', backgroundColor: '#e3f2fd', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
          <h4>Perfiles Completados de Doctores</h4>
          <p>{doctoresPerfilCompleto}</p>
        </div>
        <div style={{ padding: '20px', backgroundColor: '#f3e5f5', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
          <h4>Promedio de Pacientes por Usuario</h4>
          <p>{pacientesPorUsuarioPromedio.toFixed(2)}</p>
        </div>
      </div>
    </div>
  );
};

export default Reportes;
