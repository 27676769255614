import React, { useEffect, useState } from "react";
import { Switch } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import './userstyles.css';

const Pacientes = () => {
    const [pacientes, setPacientes] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedPaciente, setSelectedPaciente] = useState(null); 
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [ModalAlta, setModalAlta] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [newPaciente, setnewPaciente] = useState({
    });

    const fetchPacientes = async () => {
        try {
            const response = await fetch(
                "https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Paciente/api/Todos"
            );
            if (!response.ok) {
                throw new Error("Error al obtener los pacientes");
            }
            const data = await response.json(); 
            setPacientes(data);
            setLoading(false);
        } catch (err) {
            setError("Hubo un error al obtener los datos");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPacientes();
    }, []);

    const handleRowClick = (paciente) => {
        setSelectedPaciente(paciente);
        setIsModalOpen(true);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedPaciente((prevPaciente) => ({
            ...prevPaciente,
            [name]: value,
        }));
    };

    const altaPac = async () => {
        try {
            const pacienteData = {
                nombrePac: newPaciente.nombrePac,
                apellidoPac: newPaciente.apellidoPac,
                fechaNacimientoPac: newPaciente.fechaNacimientoPac,
                pesoPac: parseFloat(newPaciente.pesoPac),
                alturaPac: parseFloat(newPaciente.alturaPac),
                sangrePac: newPaciente.sangrePac,
                generoPac: newPaciente.generoPac,
                curpPac: newPaciente.curpPac,
                alergiasPac: newPaciente.alergiasPac || "",
                afeccionesPac: newPaciente.afeccionesPac || "",
                statusPac: 1,
            };

            console.log("Datos del paciente:", pacienteData);

            const response = await fetch(
                `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Paciente/api/Alta/${newPaciente.correoPac}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(pacienteData),
                }
            );
            if (!response.ok) {
                toast.error("Error al dar de alta al paciente");
            }
            toast.success("Paciente dado de alta correctamente");
            await fetchPacientes();
            cerrarModalAlta();
        } catch (err) {
            console.error("Error en altaPac:", err.message);
        }
    };


    const cerrarModal = async () => {
        await fetchPacientes();
        setIsModalOpen(false);
    };

    const cerrarModalAlta = () => {
        setModalAlta(false);
    };

    const updatePaciente = async () => {
        try {
            const response = await fetch(`https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Paciente/api/Actualizar/${selectedPaciente.idPac}`, {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(selectedPaciente),
            });

            if (!response.ok) {
                throw new Error("Error al actualizar");
            }
            await response.json();
            toast.success("Paciente actualizado correctamente");
            cerrarModal();
        } catch (err) {
            console.error(err);
            toast.error("Hubo un error al actualizar al paciente");
        }
    };

    const deletePaciente = async (id) => {
        try {
            const response = await fetch(`https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Paciente/api/Desactivar/${id}`, {
                method: 'PUT',
            });

            if (!response.ok) {
                throw new Error("Error al actualizar");
            }

            toast.success("Paciente desactivado correctamente");
            cerrarModal();
        } catch (err) {
            console.error(err);
            toast.error("Hubo un error al desactivar al paciente");
        }
    };

    const handleNewInputChange = (e) => {
        const { name, value } = e.target;
        setnewPaciente((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const filteredPacientes = pacientes.filter((paciente) => {
        const searchLower = searchTerm.toLowerCase();
        return (
            paciente.nombrePac.toLowerCase().includes(searchLower) ||
            paciente.apellidoPac.toLowerCase().includes(searchLower) ||
            paciente.curpPac.toLowerCase().includes(searchLower)
        );
    });
    

    if (error) {
        return <div>{error}</div>;
    }
    if (loading) {
        return <div>Cargando pacientes...</div>;
    }

    return (
        <div>
            <h1>Lista de Pacientes</h1>
            <Fab
                color="primary"
                aria-label="add"
                onClick={() => setModalAlta(true)}
                style={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <AddIcon />
            </Fab>
            <input
                type="text"
                placeholder="Buscar por nombre, apellido o CURP"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
            />
            <div className="table-container">
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>ID Paciente</th>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Fecha de Nacimiento</th>
                            <th>Género</th>
                            <th>CURP</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredPacientes.map((paciente) => (
                        <tr key={paciente.idPac} onClick={() => handleRowClick(paciente)}>
                            <td>{paciente.idPac}</td>
                            <td>{paciente.nombrePac}</td>
                            <td>{paciente.apellidoPac}</td>
                            <td>{new Date(paciente.fechaNacimientoPac).toLocaleDateString()}</td>
                            <td>{paciente.generoPac === "M" ? "Masculino" : "Femenino"}</td>
                            <td>{paciente.curpPac.toUpperCase()}</td>
                            <td>{paciente.statusPac === 1 ? "Activo" : "Inactivo"}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            
            {isModalOpen && selectedPaciente && (
                <div className="modal">
                    <div className="modal-content">
                        <h2 className="titulos-no-move">Editar Paciente</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                updatePaciente();
                            }}
                        >
                            <label>Nombre:</label>
                            <input
                                type="text"
                                name="nombrePac"
                                value={selectedPaciente.nombrePac}
                                onChange={handleInputChange}
                                required
                            />

                            <label>Apellido:</label>
                            <input
                                type="text"
                                name="apellidoPac"
                                value={selectedPaciente.apellidoPac}
                                onChange={handleInputChange}
                                required
                            />

                            <label>Fecha de Nacimiento:</label>
                            <input
                                type="date"
                                name="fechaNacimientoPac"
                                value={new Date(selectedPaciente.fechaNacimientoPac).toISOString().substr(0, 10)}
                                onChange={handleInputChange}
                                required
                            />

                            <label>Peso (kg):</label>
                            <input
                                type="number"
                                name="pesoPac"
                                value={selectedPaciente.pesoPac}
                                onChange={handleInputChange}
                                required
                            />

                            <label>Altura (cm):</label>
                            <input
                                type="number"
                                name="alturaPac"
                                value={selectedPaciente.alturaPac}
                                onChange={handleInputChange}
                                required
                            />

                            <label>Sangre:</label>
                            <select
                                name="sangrePac"
                                value={selectedPaciente.sangrePac}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                            </select>


                            <label>Género:</label>
                            <select
                                name="generoPac"
                                value={selectedPaciente.generoPac}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="M">Masculino</option>
                                <option value="F">Femenino</option>
                            </select>

                            <label>CURP:</label>
                            <input
                                type="text"
                                name="curpPac"
                                value={selectedPaciente.curpPac}
                                onChange={handleInputChange}
                                required
                                style={{ textTransform: 'uppercase' }}
                            />


                            <label>Alergias:</label>
                            <input
                                type="text"
                                name="alergiasPac"
                                value={selectedPaciente.alergiasPac || ""}
                                onChange={handleInputChange}
                            />

                            <label>Afecciones:</label>
                            <input
                                type="text"
                                name="afeccionesPac"
                                value={selectedPaciente.afeccionesPac || ""}
                                onChange={handleInputChange}
                            />

                            <label>Status:</label>
                            <Switch
                                checked={selectedPaciente.statusPac === 1}
                                onChange={(e) =>
                                    handleInputChange({
                                        target: {
                                            name: "statusPac",
                                            value: e.target.checked ? 1 : 0,
                                        },
                                    })
                                }
                            />

                            <div className="modal-buttons">
                                <button className="modal-submit" type="submit">
                                    Aceptar
                                </button>
                                
                                <button className="modal-delete" type="button" onClick={() => deletePaciente(selectedPaciente.idPac)}>
                                    Eliminar
                                </button>

                                <button className="modal-close" type="button" onClick={cerrarModal}>
                                    Cerrar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {ModalAlta && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Agregar Paciente</h2>
                        <form>
                            <label>Nombre:</label>
                            <input
                                type="text"
                                name="nombrePac"
                                value={newPaciente.nombrePac}
                                onChange={handleNewInputChange}
                                required
                            />

                            <label>Apellido:</label>
                            <input
                                type="text"
                                name="apellidoPac"
                                value={newPaciente.apellidoPac}
                                onChange={handleNewInputChange}
                                required
                            />

                            <label>Correo:</label>
                            <input
                                type="email"
                                name="correoPac"
                                value={newPaciente.correoPac}
                                onChange={handleNewInputChange}
                                required
                            />

                            <label>Fecha de Nacimiento:</label>
                            <input
                                type="date"
                                name="fechaNacimientoPac"
                                value={newPaciente.fechaNacimientoPac}
                                onChange={handleNewInputChange}
                                required
                            />

                            <label>Peso:</label>
                            <input
                                type="number"
                                name="pesoPac"
                                value={newPaciente.pesoPac}
                                onChange={handleNewInputChange}
                                required
                            />

                            <label>Altura:</label>
                            <input
                                type="number"
                                name="alturaPac"
                                value={newPaciente.alturaPac}
                                onChange={handleNewInputChange}
                                required
                            />

                            <label>Tipo de Sangre:</label>
                            <select
                                name="sangrePac"
                                value={newPaciente.sangrePac}
                                onChange={handleNewInputChange}
                                required
                            >
                                <option value="">Seleccione un tipo de sangre</option>
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                            </select>

                            <label>Género:</label>
                            <select
                                required
                                name="generoPac"
                                value={newPaciente.generoPac}
                                onChange={handleNewInputChange}
                            >
                                <option value="M">Masculino</option>
                                <option value="F">Femenino</option>
                            </select>

                            <label>CURP:</label>
                            <input
                                type="text"
                                name="curpPac"
                                value={newPaciente.curpPac}
                                onChange={handleNewInputChange}
                                maxLength
                                required
                            />

                            <label>Alergias:</label>
                            <input
                                type="text"
                                name="alergiasPac"
                                value={newPaciente.alergiasPac}
                                onChange={handleNewInputChange}
                            />

                            <label>Afecciones:</label>
                            <input
                                type="text"
                                name="afeccionesPac"
                                value={newPaciente.afeccionesPac}
                                onChange={handleNewInputChange}
                            />
                            <div className="modal-buttons">
                                <button className="modal-submit" type="button" onClick={altaPac}>
                                    Aceptar
                                </button>

                                <button className="modal-close" type="button" onClick={cerrarModalAlta}>
                                    Cerrar
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <ToastContainer autoClose={2000} closeButton={false} closeOnClick pauseOnHover draggable className="custom-toast" />
        </div>
    );
};

export default Pacientes;