import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

function Servicios({ currentPage }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const cards = [
    {
      date: "Gestión de Salud",
      title: "Control total",
      description: "Organiza tus citas de manera eficiente.",
      image: "/medicament.jpg",
    },
    {
      date: "Recetas Médicas",
      title: "Expide recetas electrónicas",
      description: "Expide recetas electrónicas en segundos.",
      image: "/doc.jpg",
    },
    {
      date: "Citas Disponibles",
      title: "Elige tus citas",
      description: "Elige entre cientos de citas disponibles.",
      image: "/calendar.jpg",
    },
    {
      date: "Expediente Digital",
      title: "Historial Clínico",
      description: "Acceso seguro a expedientes médicos digitalizados.",
      image: "/expediente.jpg",
    },
    {
      date: "Telemedicina",
      title: "Consultas Virtuales", 
      description: "Atención médica desde cualquier lugar.",
      image: "/virtual.jpg",
    },
    {
      date: "Emergencias",
      title: "Respuesta Inmediata",
      description: "Sistema de alertas y asistencia rápida.",
      image: "/emergency.jpg",
    }
  ];

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cards.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === cards.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className={`page ${currentPage === 4 ? 'visible' : ''}`} data-page="4" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
      <div style={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '40px 20px',
        width: '100%'
      }}>
        <div style={{
          textAlign: 'center',
          marginBottom: '4rem'
        }}>
          <span style={{
            fontSize: '1.1rem',
            fontWeight: '500',
            color: '#7fd3ed',
            letterSpacing: '2px',
            marginBottom: '1rem',
            display: 'block'
          }}>
            NUESTROS SERVICIOS
          </span>

          <h2 style={{
            fontSize: 'clamp(2.5rem, 4vw, 3.5rem)',
            fontWeight: '800',
            lineHeight: '1.1',
            marginBottom: '2rem',
            background: 'linear-gradient(90deg, #fff 0%, #7fd3ed 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}>
            Servicios para la Salud
          </h2>
        </div>

        <div className="blog-slider" style={{ marginBottom: '4rem' }}>
          <div className="blog-slider__container">
            {cards.map((card, index) => {
              let itemClass = '';
              if (index === currentIndex) itemClass = 'active';
              else if (index === (currentIndex - 1 + cards.length) % cards.length) itemClass = 'prev';
              else if (index === (currentIndex + 1) % cards.length) itemClass = 'next';

              return (
                <div
                  key={index}
                  className={`blog-slider__item ${itemClass}`}
                >
                  <div className="blog-slider__img">
                    <img src={card.image} alt={card.title} />
                  </div>
                  <div className="blog-slider__content">
                    <span className="blog-slider__code">{card.date}</span>
                    <h3 className="blog-slider__title">{card.title}</h3>
                    <p className="blog-slider__text">{card.description}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <button className="slider-arrow left" onClick={handlePrevious}>
            <FaChevronLeft />
          </button>
          <button className="slider-arrow right" onClick={handleNext}>
            <FaChevronRight />
          </button>
        </div>

        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: '2rem',
          color: 'white',
          padding: '0 10px'
        }}>
          {/* Sección para pacientes */}
          <div>
            <h3 style={{
              fontSize: 'clamp(1.5rem, 3vw, 2rem)',
              fontWeight: '700',
              color: '#7fd3ed',
              marginBottom: '1.5rem'
            }}>
              ¿Qué ofrecemos para el paciente?
            </h3>
            <p style={{
              fontSize: '1.125rem',
              lineHeight: '1.8',
              marginBottom: '2rem',
              color: 'rgba(255, 255, 255, 0.9)'
            }}>
              Nuestro objetivo es brindar soluciones digitales de última
              generación para mejorar tu experiencia en salud.
            </p>
            <ul style={{
              listStyle: 'none',
              padding: 0,
              display: 'grid',
              gap: '1rem'
            }}>
              {[
                { title: 'Gestión de citas', desc: 'para una atención sin contratiempos' },
                { title: 'Chatbot de salud', desc: 'para orientación en tiempo real' },
                { title: 'Catálogo de hospitales', desc: 'para que elijas la mejor opción' },
                { title: 'Botones de emergencia', desc: 'para asistencia rápida' },
                { title: 'Almacenamiento seguro', desc: 'de datos médicos' }
              ].map((item, index) => (
                <li key={index} style={{
                  padding: '1rem',
                  background: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: '8px',
                  backdropFilter: 'blur(10px)',
                  transition: 'all 0.3s ease',
                  cursor: 'default'
                }}>
                  <span style={{ color: '#7fd3ed', fontWeight: '600' }}>
                    {item.title}
                  </span> {item.desc}
                </li>
              ))}
            </ul>
          </div>

          {/* Sección para doctores */}
          <div>
            <h3 style={{
              fontSize: 'clamp(1.5rem, 3vw, 2rem)',
              fontWeight: '700',
              color: '#7fd3ed',
              marginBottom: '1.5rem'
            }}>
              ¿Qué ofrecemos para el doctor?
            </h3>
            <p style={{
              fontSize: '1.125rem',
              lineHeight: '1.8',
              marginBottom: '2rem',
              color: 'rgba(255, 255, 255, 0.9)'
            }}>
              Simplificamos la vida profesional de los doctores con herramientas
              avanzadas para que se enfoquen en lo que mejor hacen: cuidar de sus
              pacientes.
            </p>
            <ul style={{
              listStyle: 'none',
              padding: 0,
              display: 'grid',
              gap: '1rem'
            }}>
              {[
                { title: 'Gestión completa de citas', desc: 'para optimizar cada consulta' },
                { title: 'Generación de recetas', desc: 'digital para mayor rapidez y seguridad' },
                { title: 'Agenda de citas', desc: 'que se adapta a su ritmo de trabajo' },
                { title: 'Gestión de tiempo personalizada', desc: 'para maximizar productividad' }
              ].map((item, index) => (
                <li key={index} style={{
                  padding: '1rem',
                  background: 'rgba(255, 255, 255, 0.05)',
                  borderRadius: '8px',
                  backdropFilter: 'blur(10px)',
                  transition: 'all 0.3s ease',
                  cursor: 'default'
                }}>
                  <span style={{ color: '#7fd3ed', fontWeight: '600' }}>
                    {item.title}
                  </span> {item.desc}
                </li>
              ))}
            </ul>
            <p style={{
              fontSize: '1.125rem',
              lineHeight: '1.8',
              marginTop: '2rem',
              color: '#7fd3ed',
              fontStyle: 'italic',
              textAlign: 'center',
              padding: '1rem',
              background: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              backdropFilter: 'blur(10px)'
            }}>
              Todo con un enfoque en calidad, confidencialidad y tecnología de punta.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Servicios;