import React, { useState } from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaGlobe } from 'react-icons/fa';

const palabrasOfensivas = [
  'idiota', 'estupido', 'pendejo', 'maldito',
  'basura', 'imbecil', 'inutil', 'mierda',
  'puto', 'culero', 'jodido', 'verga',
  'puta', 'estafa', 'fraude', 'maldita',
];

const Contacto = ({ currentPage }) => {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    mensaje: '',
    aceptaPrivacidad: false
  });
  const [errores, setErrores] = useState({});
  const [enviando, setEnviando] = useState(false);
  const [mensajeEstado, setMensajeEstado] = useState('');

  const esEmailValido = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const contieneContenidoOfensivo = (texto) => {
    return palabrasOfensivas.some(palabra => 
      texto.toLowerCase().includes(palabra.toLowerCase())
    );
  };

  const validarFormulario = () => {
    const nuevosErrores = {};

    if (!formData.nombre.trim()) {
      nuevosErrores.nombre = 'El nombre es requerido';
    } else if (formData.nombre.length < 3) {
      nuevosErrores.nombre = 'El nombre debe tener al menos 3 caracteres';
    } else if (formData.nombre.length > 50) {
      nuevosErrores.nombre = 'El nombre no puede exceder los 50 caracteres';
    } else if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(formData.nombre)) {
      nuevosErrores.nombre = 'El nombre solo puede contener letras y espacios';
    }

    if (!formData.email) {
      nuevosErrores.email = 'El email es requerido';
    } else if (!esEmailValido(formData.email)) {
      nuevosErrores.email = 'El email no es válido';
    }

    if (!formData.mensaje.trim()) {
      nuevosErrores.mensaje = 'El mensaje es requerido';
    } else if (contieneContenidoOfensivo(formData.mensaje)) {
      nuevosErrores.mensaje = 'El mensaje contiene contenido inapropiado';
    } else if (formData.mensaje.length > 1000) {
      nuevosErrores.mensaje = 'El mensaje no puede exceder los 1000 caracteres';
    }

    if (!formData.aceptaPrivacidad) {
      nuevosErrores.privacidad = 'Debes aceptar la política de privacidad';
    }

    return nuevosErrores;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const nuevosErrores = validarFormulario();

    if (Object.keys(nuevosErrores).length > 0) {
      setErrores(nuevosErrores);
      return;
    }

    setEnviando(true);
    setErrores({});

    try {
      const response = await fetch('https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/Registro/formulariocontacto', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'accept': '*/*'
        },
        body: JSON.stringify({
          nombre: formData.nombre,
          correo: formData.email,
          mensaje: formData.mensaje
        })
      });

      if (!response.ok) {
        throw new Error('Error al enviar el mensaje');
      }

      const data = await response.json();
      
      if (data.message === "Formulario Enviado") {
        setMensajeEstado('¡Mensaje enviado con éxito!');
        setFormData({
          nombre: '',
          email: '',
          mensaje: '',
          aceptaPrivacidad: false
        });
      } else {
        throw new Error('Respuesta inesperada del servidor');
      }
    } catch (error) {
      setMensajeEstado('Error al enviar el mensaje. Por favor, intenta nuevamente.');
      console.error('Error:', error);
    } finally {
      setEnviando(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    
    if (errores[name]) {
      setErrores(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const styles = {
    pageContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '80px 20px',
      display: 'flex',
      flexDirection: 'column',
      gap: '60px',
    },
    formSection: {
      color: 'white',
      width: '100%',
    },
    title: {
      fontSize: 'clamp(2.5rem, 4vw, 3.5rem)',
      fontWeight: '800',
      lineHeight: '1.1',
      marginBottom: '1.5rem',
      background: 'linear-gradient(90deg, #fff 0%, #7fd3ed 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    description: {
      fontSize: '1.1rem',
      color: 'rgba(255, 255, 255, 0.7)',
      marginBottom: '2rem',
      lineHeight: '1.6',
    },
    statusMessage: (isSuccess) => ({
      padding: '1rem',
      borderRadius: '8px',
      backgroundColor: isSuccess ? 'rgba(0, 255, 0, 0.1)' : 'rgba(255, 0, 0, 0.1)',
      color: isSuccess ? '#4caf50' : '#f44336',
      marginBottom: '1rem',
    }),
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1.5rem',
      maxWidth: '600px',
    },
    input: (hasError) => ({
      width: '100%',
      padding: '1rem',
      borderRadius: '8px',
      border: `1px solid ${hasError ? '#f44336' : 'rgba(255, 255, 255, 0.1)'}`,
      background: 'rgba(255, 255, 255, 0.05)',
      color: 'white',
      outline: 'none',
      transition: 'border-color 0.3s ease',
    }),
    textarea: (hasError) => ({
      width: '100%',
      padding: '1rem',
      borderRadius: '8px',
      border: `1px solid ${hasError ? '#f44336' : 'rgba(255, 255, 255, 0.1)'}`,
      background: 'rgba(255, 255, 255, 0.05)',
      color: 'white',
      minHeight: '120px',
      resize: 'vertical',
      outline: 'none',
      transition: 'border-color 0.3s ease',
    }),
    errorText: {
      color: '#f44336',
      fontSize: '0.875rem',
      marginTop: '0.5rem',
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    checkboxLabel: {
      color: 'rgba(255, 255, 255, 0.7)',
      cursor: 'pointer',
      userSelect: 'none',
    },
    submitButton: (isDisabled) => ({
      background: 'linear-gradient(90deg, #7fd3ed 0%, #89f7fe 100%)',
      color: '#1a365d',
      padding: '1rem',
      borderRadius: '8px',
      border: 'none',
      fontWeight: '600',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      opacity: isDisabled ? 0.7 : 1,
      transition: 'all 0.3s ease',
    }),
    contactInfo: {
      background: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '20px',
      padding: '2rem',
      backdropFilter: 'blur(10px)',
      width: '100%',
      maxWidth: '600px',
      alignSelf: 'center',
      boxShadow: '0 4px 24px rgba(0, 0, 0, 0.1)',
    },
    contactGrid: {
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },
    contactItem: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '1rem',
      padding: '1.5rem',
      background: 'rgba(255, 255, 255, 0.03)',
      borderRadius: '12px',
      transition: 'transform 0.3s ease, background-color 0.3s ease',
    },
    icon: {
      color: '#7fd3ed',
      marginTop: '0.25rem',
    },
    contactContent: {
      flex: 1,
    },
    contactTitle: {
      color: '#7fd3ed',
      fontSize: '1.1rem',
      marginBottom: '0.5rem',
    },
    contactLink: {
      color: 'white',
      textDecoration: 'none',
      fontSize: '1rem',
      lineHeight: '1.5',
      wordBreak: 'break-word',
      display: 'block',
      transition: 'color 0.3s ease',
    },
    copyright: {
      color: 'rgba(255, 255, 255, 0.5)',
      textAlign: 'center',
      marginTop: '2rem',
      fontSize: '0.9rem',
    },
  };

  return (
    <div className={`page ${currentPage === 5 ? 'visible' : ''}`} data-page="5">
      <div style={styles.pageContainer}>
        <div style={styles.formSection}>
          <h2 style={styles.title}>
            Contáctanos
          </h2>

          <p style={styles.description}>
            ¿Tienes alguna pregunta sobre nuestros servicios? Estamos aquí para ayudarte.
          </p>

          {mensajeEstado && (
            <div style={styles.statusMessage(mensajeEstado.includes('éxito'))}>
              {mensajeEstado}
            </div>
          )}

          <form onSubmit={handleSubmit} style={styles.form}>
            <div>
              <input 
                type="text"
                name="nombre"
                value={formData.nombre}
                onChange={handleChange}
                placeholder="Tu Nombre Completo"
                style={styles.input(errores.nombre)}
                maxLength={50}
              />
              {errores.nombre && (
                <p style={styles.errorText}>{errores.nombre}</p>
              )}
            </div>

            <div>
              <input 
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Tu Correo Electrónico"
                style={styles.input(errores.email)}
              />
              {errores.email && (
                <p style={styles.errorText}>{errores.email}</p>
              )}
            </div>

            <div>
              <textarea 
                name="mensaje"
                value={formData.mensaje}
                onChange={handleChange}
                placeholder="Tu Mensaje"
                style={styles.textarea(errores.mensaje)}
                maxLength={1000}
              />
              {errores.mensaje && (
                <p style={styles.errorText}>{errores.mensaje}</p>
              )}
              <span style={{
                color: 'rgba(255, 255, 255, 0.5)',
                fontSize: '0.875rem',
                float: 'right',
                marginTop: '0.5rem'
              }}>
                {formData.mensaje.length}/1000
              </span>
            </div>

            <div style={styles.checkboxContainer}>
              <input
                type="checkbox"
                id="privacy"
                name="aceptaPrivacidad"
                checked={formData.aceptaPrivacidad}
                onChange={handleChange}
              />
              <label htmlFor="privacy" style={styles.checkboxLabel}>
                Acepto la política de privacidad
              </label>
            </div>
            {errores.privacidad && (
              <p style={styles.errorText}>{errores.privacidad}</p>
            )}

            <button 
              type="submit"
              disabled={enviando}
              style={styles.submitButton(enviando)}
            >
              {enviando ? 'Enviando...' : 'Enviar Mensaje'}
            </button>
          </form>
        </div>

        <div style={styles.contactInfo}>
          <div style={styles.contactGrid}>
            {[
              {
                icon: <FaPhone size={24} />,
                title: 'Teléfono',
                content: '442 449 4150',
                link: 'tel:4424494150'
              },
              {
                icon: <FaGlobe size={24} />,
                title: 'Sitio Web',
                content: 'www.hcfolder.com',
                link: 'http://www.hcfolder.com'
              },
              {
                icon: <FaEnvelope size={24} />,
                title: 'E-Mail',
                content: 'ventastecnicas@hcpromedical.com',
                link: 'mailto:ventastecnicas@hcpromedical.com'
              },
              {
                icon: <FaMapMarkerAlt size={24} />,
                title: 'Ubicación',
                content: 'Anillo Vial, Fray Junípero Serra km número 20012, Col. El Salitre, C.P. 76127',
                link: 'https://maps.google.com/?q=Anillo+Vial+Fray+Junípero+Serra+km+20012+El+Salitre+76127'
              }
            ].map((item, index) => (
              <div key={index} style={styles.contactItem}>
                <div style={styles.icon}>
                  {item.icon}
                </div>
                <div style={styles.contactContent}>
                  <h3 style={styles.contactTitle}>
                    {item.title}
                  </h3>
                  <a 
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.contactLink}
                    >
                      {item.content}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
  
          <p style={styles.copyright}>
            Todos los derechos reservados © 2024
          </p>
        </div>
      </div>
    );
  };
  
  export default Contacto;