import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../estilos/receta.css";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import '../estilos/docstyles.css'

function Recetas() {
  const location = useLocation();
  const navigate = useNavigate();
  const { Cita } = location.state || {};
  const [citas, setCitas] = useState([]);
  const [medicamentos, setMedicamentos] = useState([{ nombre: "", intervalo: "", duracion: "" }]);
  const [diagnosticoRec, setDiagnosticoRec] = useState("");
  const [notaRec, setNotaRec] = useState("");
  const [loading, setLoading] = useState(false);
  const [ModalAlta, setModalAlta] = useState(false);
  const [newMedicamento, setNewMedicamento] = useState({ nombre: "", intervalo: "", duracion: "" });

  const abrirModalAlta = () => {
    setModalAlta(true);
    setNewMedicamento({ nombre: "", intervalo: "", duracion: "" });
  };

  const cerrarModalAlta = () => {
    setModalAlta(false);
  };

  const agregarMedicamento = () => {
    if (!newMedicamento.nombre || !newMedicamento.intervalo || !newMedicamento.duracion) {
      toast.error("Por favor, complete todos los campos del medicamento.");
      return;
    }
    setMedicamentos([...medicamentos, newMedicamento]);
    cerrarModalAlta();
    toast.success("Medicamento agregado");
  };

  const eliminarMedicamento = (index) => {
    if (medicamentos.length > 1) {
      const nuevosMedicamentos = medicamentos.filter((_, i) => i !== index);
      setMedicamentos(nuevosMedicamentos);
      toast.success("Medicamento eliminado");
    } else {
      toast.warning("Debe haber al menos un medicamento.");
    }
  };

  const handleMedicamentoChange = (index, field, value) => {
    const nuevosMedicamentos = medicamentos.map((medicamento, i) =>
      i === index ? { ...medicamento, [field]: value } : medicamento
    );
    setMedicamentos(nuevosMedicamentos);
  };

  const handleNewMedicamentoChange = (field, value) => {
    setNewMedicamento({ ...newMedicamento, [field]: value });
  };

  const validarMedicamentos = () => {
    return medicamentos.every(medicamento =>
      medicamento.nombre && medicamento.intervalo && medicamento.duracion
    );
  };

  const enviarReceta = async () => {
    const recetaData = {
      medicamentosRec: medicamentos.map(m => m.nombre).join(", "),
      intervalosRec: medicamentos.map(m => m.intervalo).join(", "),
      duracionRec: medicamentos.map(m => m.duracion).join(", "),
      diagnosticoRec: diagnosticoRec,
      notaRec: notaRec,
      idCita: Cita.idCita
    };

    try {
      const response = await fetch('https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Receta/api/alta_receta', {
        method: 'POST',
        headers: {
          'accept': '*/*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(recetaData),
      });

      const result = await response.json();
      toast.success(result.message || "Receta enviada con éxito");
    } catch (error) {
      toast.error("Error al enviar la receta");
      console.error(error);
    }
  };

  const actualizarCita = async (cita) => {
    const citaActualizada = { ...cita, statusCita: 4 };

    try {
      const response = await fetch(
        `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Cita/api/actualizarCita`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(citaActualizada),
        }
      );

      if (response.ok) {
        const nuevasCitas = citas.filter(c => c.idCita !== cita.idCita);
        setCitas(nuevasCitas);
        toast.success('Cita actualizada correctamente');
      } else {
        toast.error('Error al actualizar la cita');
      }
    } catch (error) {
      toast.error('Error en la solicitud');
      console.error('Error en la solicitud:', error);
    }
  };

  const handleEnviarRecetaYActualizarCita = async () => {
    if (!validarMedicamentos()) {
      toast.error("Por favor completa todos los campos de los medicamentos.");
      return;
    }

    setLoading(true);
    try {
      await enviarReceta();
      await actualizarCita(Cita);
      navigate("/Home/Finalizadas");
    } catch (error) {
      toast.error("Hubo un error al crear la receta.");
    } finally {
      setLoading(false);
    }
  };

return (
  <div className="doctor-fondo">
    {/* Barra superior con botones */}
    <div className="barra-superior">

      <button 
        className="" 
        onClick={abrirModalAlta}
      >
        Agregar Medicamento
      </button>
      <button 
        className="" 
        onClick={handleEnviarRecetaYActualizarCita} 
        disabled={loading}
      >
        {loading ? "Enviando..." : "Enviar Receta"}
      </button>
      <button 
        onClick={() => navigate('/home/finalizadas')} 
        className=""
      >
        Cancelar Receta
      </button>
    </div>
    <div className="contenido-principal">
      <h2 className="doctor-title">Generar Receta</h2>
      <div className="form-container">
        <div className="diagnostico-container">
          <label className="doctor-subtitle">Diagnóstico</label>
          <input
            className="large-input"
            placeholder="Ingrese el diagnóstico"
            value={diagnosticoRec}
            maxLength="400"
            onChange={(e) => setDiagnosticoRec(e.target.value)}
          />
        </div>
        <div className="nota-container">
            <label className="doctor-subtitle">Nota</label>
            <input
              className="large-input"
              placeholder="Ingrese una nota adicional (opcional)"
              value={notaRec}
              maxLength="400"
              onChange={(e) => setNotaRec(e.target.value)}
            />
          </div>
        <div className="medicamentos-container">
          {medicamentos.map((medicamento, index) => (
            <div key={index} className="medicamento-box">
              <div className="project-box color-1">
                <div className="project-box-header">
                  <input
                    type="text"
                    placeholder="Nombre del Medicamento"
                    value={medicamento.nombre}
                    maxLength="70"
                    onChange={(e) => handleMedicamentoChange(index, "nombre", e.target.value)}
                    className="global-input nombre-medicamento"
                  />
                </div>
                <div className="medicamento-intervalos">
                  <input
                    type="number"
                    placeholder="Intervalo (horas)"
                    value={medicamento.intervalo}
                    onChange={(e) => handleMedicamentoChange(index, "intervalo", e.target.value)}
                    className="global-input intervalo"
                    min="1"
                    max="24"
                  />
                  <input
                    type="number"
                    placeholder="Duración (días)"
                    value={medicamento.duracion}
                    onChange={(e) => handleMedicamentoChange(index, "duracion", e.target.value)}
                    className="global-input duracion"
                    min="1"
                    max="365"
                  />
                </div>

                {medicamentos.length > 1 && (
                  <button onClick={() => eliminarMedicamento(index)} className="btn-eliminar">Eliminar</button>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    {/* Modal para agregar medicamento */}
    {ModalAlta && (
      <div className="modal">
        <div className="modal-content">
          <h2>Agregar Medicamento</h2>
          <form>
            <label>Nombre del Medicamento:</label>
            <input
              type="text"
              name="nombre"
              value={newMedicamento.nombre}
              onChange={(e) => handleNewMedicamentoChange("nombre", e.target.value)}
              required
            />

            <label>Intervalo (horas):</label>
            <input
              type="number"
              name="intervalo"
              min="1"
              max="24"
              value={newMedicamento.intervalo}
              onChange={(e) => handleNewMedicamentoChange("intervalo", e.target.value)}
              required
            />

            <label>Duración (días):</label>
            <input
              type="number"
              name="duracion"
              min="1"
              max="365"
              value={newMedicamento.duracion}
              onChange={(e) => handleNewMedicamentoChange("duracion", e.target.value)}
              required
            />
            <div className="modal-buttons">
              <button type="button" onClick={cerrarModalAlta}>Cancelar</button>
              <button type="button" onClick={agregarMedicamento}>Agregar</button>
            </div>
          </form>
        </div>
      </div>
    )}
    <ToastContainer />
  </div>
);

}

export default Recetas;