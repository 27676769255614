import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Typography } from "@mui/material"; 

const Perfil = () => {
  const [usuario, setUsuario] = useState(null);
  const [loading, setLoading] = useState(true);

  const correo = localStorage.getItem("usuario");

  useEffect(() => {
    if (correo) {
      const fetchUsuarioData = async () => {
        try {
          const response = await fetch(
            `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/List/${correo}`
          );
          
          if (!response.ok) {
            setLoading(false);
            return;
          }

          const data = await response.json();
          setUsuario(data[0]); // Usamos el primer objeto si es una lista
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };

      fetchUsuarioData();
    } else {
      setLoading(false);
    }
  }, [correo]);

  if (loading) {
    return <div>Cargando perfil...</div>;
  }

  if (!usuario) {
    return <div>No se encontró el usuario.</div>;
  }

  return (
    <div
      style={{
        minHeight: "100vh", 
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        backgroundColor: "#1F2937", 
        padding: "20px"
      }}
    >
      <Card
        style={{
          width: "100%",
          maxWidth: "700px",
          padding: "20px",
          borderRadius: "12px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          marginBottom: "20px",
          fontSize: "20px",
        }}
      >
        <CardContent>
          <Typography variant="h4" gutterBottom className="text-center">
            Mi Perfil
          </Typography>

          <div className="d-flex flex-column gap-3">
            <div>
              <strong>Cédula:</strong> {usuario.cedDoc || "No disponible"}
            </div>
            <div>
              <strong>Banco:</strong> {usuario.bancoDoc || "No disponible"}
            </div>
            <div>
              <strong>Cuenta:</strong> {usuario.cuentaDoc || "No disponible"}
            </div>
            <div>
              <strong>Curp:</strong> {usuario.curpDoc || "No disponible"}
            </div>
            <div>
              <strong>Link:</strong>{" "}
              <a href={usuario.linkDoc || "#"} target="_blank" rel="noopener noreferrer">
                {usuario.linkDoc ? usuario.linkDoc : "No disponible"}
              </a>
            </div>
          </div>
        </CardContent>
      </Card>

      <Button
        variant="contained"
        color="primary"
        style={{
          width: "200px",
          fontSize: "16px",
          borderRadius: "8px",
        }}
        onClick={() => window.location.href = '/Home'}
      >
        Ir al Menú
      </Button>
    </div>
  );
};

export default Perfil;
