import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import NoticiasUsuarios from "./NoticiasUsuarios";
import NoticiasDoc from "./NoticiasDoc";

const Noticias = () => {
    const [view, setView] = useState("generales");

    const handleChange = (event, newValue) => {
        setView(newValue);
    };

    return (
        <Box sx={{ width: "100%", typography: "body1"}}>
            <h1 style={{ textAlign: "center" }}>Noticias</h1>
            <Tabs
                value={view}
                onChange={handleChange}
                centered
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab value="generales" label="Noticias Usuarios" />
                <Tab value="doctor" label="Noticias Doctor" />
            </Tabs>

            <Box sx={{ mt: 2 }}>
                {view === "generales" && <NoticiasUsuarios />}
                {view === "doctor" && <NoticiasDoc />}
            </Box>
        </Box>
    );
};

export default Noticias;
