import '../estilos/header_doc.css';
import { Link, useNavigate } from 'react-router-dom';

const HeaderDoc = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('usuario');
    navigate('/');
  };

  return (
    <header className="navbar-transparent">
      <div className="container-header">
        <h1 className="titulos-no-move" style={{ marginTop: '25px' }}>HC Folder</h1>
        <nav className="nav-links">
          <Link className='link' to="/Disponibles">Disponibles</Link>
          <Link className='link' to="/Aceptadas">Aceptadas</Link>
          <Link className='link' to="/Finalizadas">Finalizadas</Link>
          {/* <Link className='link' to="/Mycitas">Consultas Asignadas</Link> */}
        </nav>
        <div className="buttons-container">
          <button className="buttonHeader" onClick={handleLogout}>
            Cerrar Sesión
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderDoc;
