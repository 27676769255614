import { useEffect, useState } from "react";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './userstyles.css';

const Doctores = () => {
    const [doctores, setDoctores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDoc, setSelectedDoc] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalAltaOpen, setIsModalAltaOpen] = useState(false);
    const [correoDoctores, setCorreo] = useState([]);
    const [newDoctor, setNewDoctor] = useState({
        correoUs: '',
        cedDoc: '',
        genDoc: '',
        validacionDoc: true,
        cuentaDoc: '',
        bancoDoc: '',
        curpDoc: '',
    });

    const fetchCorreoDoctores = async () => {
        try {
            const response = await fetch(`https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/Disponibles`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                console.error('Error', response.statusText);
                return;
            }

            const data = await response.json();
            const correos = data.map(doctor => doctor.correoUs);
            setCorreo(correos);

        } catch (error) {
            console.error('Error en la solicitud:', error);
        }
    };

    const fetchDoctores = async () => {
        try {
            const response = await fetch(
                "https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/Todos"
            );
            if (!response.ok) {
                throw new Error("Error al obtener los doctores");
            }
            const data = await response.json();
            setDoctores(data);

            setLoading(false);

        } catch (err) {
            setError("Hubo un error al obtener los datos");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDoctores();
        fetchCorreoDoctores();
    }, []);

    const altaDoctor = async () => {
        try {
            const response = await fetch(`API_URL_ALTA_DOCTOR/${newDoctor.correoUs}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newDoctor),
            });
    
            if (response.ok) {
                toast.success('Doctor agregado con éxito');  
            } else {
                toast.error('Error al agregar el doctor'); 
            }
            
            closeModalAlta();
        } catch (err) {
            toast.error('Hubo un error al enviar los datos');
            console.error("Error al enviar datos:", err);
        }
    };
    
    const closeModal = () => {
        setIsModalOpen(false);
        fetchDoctores();
    };

    const closeModalAlta = () => {
        setIsModalAltaOpen(false);
        setNewDoctor({
            correoUs: '',
            cedDoc: '',
            genDoc: '',
            validacionDoc: true,
            cuentaDoc: '',
            bancoDoc: '',
            curpDoc: ''
        });
        fetchDoctores();
    };

    const actualizarDoctor = async () => {
        try {
            const response = await fetch("API_URL_ACTUALIZAR_DOCTOR", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedDoc),
            });
    
            if (response.ok) {
                toast.success('Doctor actualizado con éxito'); 
            } else {
                toast.error('Error al actualizar el doctor');
            }
            
            closeModal();
        } catch (err) {
            toast.error('Hubo un error al actualizar el doctor');
            console.error("Error al actualizar el doctor:", err);
        }
    };
    
    const handleRowClick = (doctor) => {
        setSelectedDoc(doctor);
        setIsModalOpen(true);
    };

    const handleNewInputChange = (e) => {
        const { name, value } = e.target;
        setNewDoctor((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedDoc((prevDoctor) => ({
            ...prevDoctor,
            [name]: value,
        }));
    };

    if (error) {
        return <div>{error}</div>;
    }
    if (loading) {
        return <div>Cargando doctores...</div>;
    }

    return (
        <div>
            <Fab
                color="primary"
                aria-label="add"
                onClick={() => setIsModalAltaOpen(true)}
                style={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <AddIcon />
            </Fab>
            <div className="table-container">
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>ID de Doctor</th>
                            <th>Cédula</th>
                            <th>Género</th>
                            <th>Id de Usuario</th>
                            <th>Cuenta Bancaria</th>
                            <th>Nombre del Banco</th>
                            <th>CURP</th>
                            <th>Link de consulta médica</th>
                        </tr>
                    </thead>
                    <tbody>
                        {doctores.map((doctor) => (
                            <tr key={doctor.idDoc} onClick={() => handleRowClick(doctor)}>
                                <td>{doctor.idDoc}</td>
                                <td>{doctor.cedDoc}</td>
                                <td>{doctor.genDoc === "M" ? "Masculino" : "Femenino"}</td>
                                <td>{doctor.idUs}</td>
                                <td>{doctor.cuentaDoc}</td>
                                <td>{doctor.bancoDoc}</td>
                                <td>{doctor.curpDoc}</td>
                                <td>{doctor.linkDoc}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {isModalOpen && selectedDoc && (
                <div className="modal">
                    <div className="modal-content">
                        <h2 className="titulos-no-move">Editar Doctor</h2>
                        <label>ID del Doctor:</label>
                        <input
                            type="text"
                            name="idDoc"
                            value={selectedDoc.idDoc}
                            onChange={handleInputChange}
                            disabled // El ID no debería ser editable
                        />
                        <label>Cédula:</label>
                        <input
                            type="text"
                            name="cedDoc"
                            value={selectedDoc.cedDoc}
                            onChange={handleInputChange}
                        />
                        <label>Género:</label>
                        <select
                            name="genDoc"
                            value={selectedDoc.genDoc}
                            onChange={handleInputChange}
                        >
                            <option value="M">Masculino</option>
                            <option value="F">Femenino</option>
                        </select>
                        <label>ID usuario:</label>
                        <input
                            type="text"
                            name="idUs"
                            value={selectedDoc.idUs}
                            onChange={handleInputChange}
                        />
                        <label>Cuenta Bancaria:</label>
                        <input
                            type="text"
                            name="cuentaDoc"
                            value={selectedDoc.cuentaDoc}
                            onChange={handleInputChange}
                        />
                        <label>Nombre del Banco:</label>
                        <input
                            type="text"
                            name="bancoDoc"
                            value={selectedDoc.bancoDoc}
                            onChange={handleInputChange}
                        />
                        <label>CURP:</label>
                        <input
                            type="text"
                            name="curpDoc"
                            value={selectedDoc.curpDoc}
                            onChange={(e) => {
                                const value = e.target.value;
                                const curpRegex = /^[A-Z]{4}[0-9]{6}[A-Z]{6}[0-9]{2}$/;
                                if (!curpRegex.test(value)) {
                                    alert("El CURP ingresado no es válido");
                                } else {
                                    handleInputChange(e);
                                }
                            }}
                        />
                        <div className="modal-buttons">
                            <button className="modal-submit" onClick={actualizarDoctor}>
                                Aceptar
                            </button>
                            <button className="modal-close" onClick={closeModal}>
                                Cerrar
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isModalAltaOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h2 className="titulos-no-move">Alta de doctor</h2>
                        <label>Seleccione un correo Doctor:</label>
                        <select onChange={(e) => setNewDoctor(prev => ({ ...prev, correoUs: e.target.value }))}>
                            <option value="" disabled selected>Seleccione un correo</option>
                            {correoDoctores.map((correo, index) => (
                                <option key={index} value={correo}>
                                    {correo}
                                </option>
                            ))}
                        </select>
                        <label>Cédula:</label>
                        <input
                            type="text"
                            name="cedDoc"
                            value={newDoctor.cedDoc}
                            onChange={handleNewInputChange}
                        />
                        <label>Género:</label>
                        <select
                            name="genDoc"
                            value={newDoctor.genDoc}
                            onChange={handleNewInputChange}
                        >
                            <option value="M">Masculino</option>
                            <option value="F">Femenino</option>
                        </select>
                        <label>Cuenta Bancaria:</label>
                        <input
                            type="text"
                            name="cuentaDoc"
                            value={newDoctor.cuentaDoc}
                            onChange={handleNewInputChange}
                        />
                        <label>Nombre del Banco:</label>
                        <input
                            type="text"
                            name="bancoDoc"
                            value={newDoctor.bancoDoc}
                            onChange={handleNewInputChange}
                        />
                        <label>CURP:</label>
                        <input
                            type="text"
                            name="curpDoc"
                            value={newDoctor.curpDoc}
                            onChange={(e) => {
                                const value = e.target.value;
                                const curpRegex = /^[A-Z]{4}[0-9]{6}[A-Z]{6}[0-9]{2}$/;
                                if (!curpRegex.test(value)) {
                                    alert("El CURP ingresado no es válido");
                                } else {
                                    handleNewInputChange(e);
                                }
                            }}
                        />
                        <div className="modal-buttons">
                            <button onClick={altaDoctor}>Aceptar</button>
                            <button onClick={closeModalAlta}>Cerrar</button>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer autoClose={2000} closeButton={false} closeOnClick pauseOnHover draggable className="custom-toast" />
        </div>
    );
};

export default Doctores;