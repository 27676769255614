import React from 'react';

function Acercade({ currentPage }) {
  return (
    <div className={`page ${currentPage === 3 ? 'visible' : ''}`} data-page="3" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
      <div style={{
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '40px 20px',
        display: 'flex',
        flexDirection: 'column'
      }}>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: '60px',
          alignItems: 'center',
          width: '100%'
        }}>
          {/* Espacio para la imagen */}
          <div style={{
            position: 'relative',
            height: '600px',
            borderRadius: '20px',
            overflow: 'hidden',
            '@media (max-width: 768px)': {
              height: '400px'
            }
          }}>
            <img 
              src="/fondo-1.jpg" 
              alt="HC Folder Team"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
          </div>

          {/* Contenido de texto */}
          <div style={{
            color: 'white'
          }}>
            <span style={{
              fontSize: '1.1rem',
              fontWeight: '500',
              color: '#7fd3ed',
              letterSpacing: '2px',
              marginBottom: '1rem',
              display: 'block'
            }}>
              QUIÉNES SOMOS
            </span>

            <h2 style={{
              fontSize: 'clamp(2.5rem, 4vw, 3.5rem)',
              fontWeight: '800',
              lineHeight: '1.1',
              marginBottom: '2rem',
              background: 'linear-gradient(90deg, #fff 0%, #7fd3ed 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
              HC Folder: Tu socio para la gestión médica
            </h2>

            <div style={{
              fontSize: '1.125rem',
              lineHeight: '1.8',
              color: 'rgba(255, 255, 255, 0.9)'
            }}>
              <p style={{ marginBottom: '2rem' }}>
                <strong style={{ color: '#7fd3ed' }}>HC Folder</strong> es una innovadora aplicación diseñada para
                ayudar a los médicos a gestionar su consulta de manera eficiente.{" "}
                <strong style={{ color: '#7fd3ed' }}>HC Promedical</strong>, dueños de{" "}
                <strong style={{ color: '#7fd3ed' }}>HC Folder</strong>, cuenta con más de{" "}
                <strong style={{ color: '#7fd3ed' }}>25 años de experiencia</strong> en el sector de la salud.
              </p>

              <h3 style={{
                fontSize: '1.5rem',
                fontWeight: '600',
                marginBottom: '1.5rem',
                color: 'white'
              }}>
                ¿Qué ofrece HC Folder?
              </h3>

              <ul style={{
                listStyle: 'none',
                padding: 0,
                margin: 0,
                display: 'grid',
                gap: '1.5rem'
              }}>
                {[
                  {
                    title: 'Gestión de citas',
                    desc: 'Organiza tus citas de manera eficiente, reduciendo el tiempo de espera y mejorando la atención al paciente.'
                  },
                  {
                    title: 'Historial médico electrónico',
                    desc: 'Accede rápidamente al historial médico de tus pacientes, permitiendo una atención más personalizada y efectiva.'
                  },
                  {
                    title: 'Recetas electrónicas',
                    desc: 'Genera y envía recetas electrónicas de forma rápida y sencilla, mejorando la seguridad y la eficiencia en la atención médica.'
                  },
                  {
                    title: 'Soporte técnico',
                    desc: 'Contamos con un equipo de soporte disponible para resolver cualquier duda o problema que puedas tener con la aplicación.'
                  }
                ].map((item, index) => (
                  <li key={index} style={{
                    padding: '1.5rem',
                    background: 'rgba(255, 255, 255, 0.05)',
                    borderRadius: '12px',
                    backdropFilter: 'blur(10px)',
                    transition: 'transform 0.3s ease',
                    cursor: 'default'
                  }}>
                    <strong style={{
                      color: '#7fd3ed',
                      display: 'block',
                      marginBottom: '0.5rem',
                      fontSize: '1.1rem'
                    }}>
                      {item.title}
                    </strong>
                    {item.desc}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Acercade;