import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Asegúrate de importar los estilos de toast

const DetalleHospital = () => {
    const [hospitales, setHospitales] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [error, setError] = useState(null);
    const [selectedHospital, setSelectedHospital] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState(1);
    const [uploadFile, setUploadFile] = useState(null);

    useEffect(() => {
        const fetchHospitales = async () => {
            try {
                const response = await fetch("https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Hospital/api/Todos");
                if (!response.ok) {
                    throw new Error("Error al obtener los hospitales");
                }
                const data = await response.json();
                setHospitales(data);
            } catch (err) {
                setError("Hubo un error al obtener los datos");
            } finally {
                setLoading(false);
            }
        };

        fetchHospitales();
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredHospitales = hospitales.filter(hospital =>
        hospital.nombreHos.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const openModal = (hospital) => {
        setSelectedHospital(hospital);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setSelectedHospital(null);
        setUploadFile(null); 
    };

    const handleModeChange = (mode) => {
        setModalMode(mode);
    };

    const handleFileChange = (event) => {
        setUploadFile(event.target.files[0]);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length > 0) {
            setUploadFile(files[0]); // Solo tomar el primer archivo arrastrado
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault(); // Evitar el comportamiento por defecto para permitir el drop
    };

    const handleUpload = async () => {
        if (!uploadFile || !selectedHospital) {
            toast.error("Por favor selecciona un archivo y un hospital");
            return;
        }

        const formData = new FormData();
        formData.append("file", uploadFile);

        try {
            const response = await fetch(`https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/Uploadhospital/upload/${selectedHospital.idHos}`, {
                method: 'POST',
                body: formData,
            });
            if (!response.ok) {
                throw new Error("Error al subir la imagen");
            }
            toast.success("Imagen subida correctamente");
        } catch (err) {
            toast.error("Hubo un error al subir la imagen");
        }
        closeModal();
    };

    if (loading) {
        return (
            <div style={{ textAlign: 'center', padding: '50px' }}>
                <p>Cargando hospitales...</p>
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <h1>Lista de Hospitales</h1>
            <input
                type="text"
                placeholder="Buscar por nombre..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
            />
            <div className="table-container">
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Correo</th>
                            <th>Status</th>
                            <th>Especialidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredHospitales.length === 0 ? (
                            <tr>
                                <td colSpan="7">No se encontraron hospitales que coincidan con la búsqueda.</td>
                            </tr>
                        ) : (
                            filteredHospitales.map((hospital) => (
                                <tr key={hospital.idHos} onClick={() => openModal(hospital)}>
                                    <td>{hospital.nombreHos}</td>
                                    <td>{hospital.correoHos}</td>
                                    <td>{hospital.statusHos === 1 ? "Activo" : "Inactivo"}</td>
                                    <td>{hospital.especialHos === 1 ? "Sí" : "No"}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            {/* Modal */}
            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>{selectedHospital.nombreHos}</h2>
                        <div className="modal-buttons">
                            <button onClick={() => handleModeChange(1)}>Carrusel</button>
                            <button onClick={() => handleModeChange(2)}>Modo 2</button>
                            <button onClick={() => handleModeChange(3)}>Modo 3</button>
                            <button onClick={() => handleModeChange(4)}>Modo 4</button>
                        </div>

                        {modalMode === 1 && (
                            <div>
                                <label>Agregar Imágenes al hospital</label>
                                {/* Área de arrastrar y soltar */}
                                <div 
                                    className="drop-area"
                                    onDrop={handleDrop}
                                    onDragOver={handleDragOver}
                                >
                                    <p>{uploadFile ? uploadFile.name : "Arrastra y suelta un archivo aquí o haz cic en el boton"}</p>
                                </div>
                                <input 
                                    type="file" 
                                    onChange={handleFileChange} 
                                    style={{ display: "none" }} 
                                    id="file-upload" 
                                />
                                <label htmlFor="file-upload" className="file-upload-button">Seleccionar archivo</label>
                                <div className="modal-buttons"> 
                                    <button onClick={handleUpload}>Guardar</button>
                                    <button className="modal-close" onClick={closeModal}>Cerrar</button>
                                </div>
                            </div>
                        )}
                        {modalMode === 2 && (
                            <div>
                                <label>Status:</label>
                                <p>{selectedHospital.statusHos === 1 ? "Activo" : "Inactivo"}</p>
                            </div>
                        )}
                        {modalMode === 3 && (
                            <div>
                                <label>Especialidad:</label>
                                <p>{selectedHospital.especialHos === 1 ? "Sí" : "No"}</p>
                            </div>
                        )}
                        {modalMode === 4 && (
                            <div>
                                <label>Más información:</label>
                                <p>Aquí puedes incluir más detalles sobre el hospital.</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <ToastContainer autoClose={2000} closeButton={false} closeOnClick pauseOnHover draggable className="custom-toast" />
        </div>
    );
};

export default DetalleHospital;