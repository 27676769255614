import { useEffect, useState } from "react";
import './userstyles.css';

const Calificaciones = () => {
    const [calificaciones, setCalificaciones] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchCalificaciones = async () => {
        try {
            const response = await fetch("https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/Calificaciones", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'text/plain'
                }
            });

            if (!response.ok) {
                throw new Error("Error al obtener las calificaciones");
            }

            const data = await response.json();
            setCalificaciones(data);
            setLoading(false);
        } catch (err) {
            setError("Hubo un error al obtener los datos");
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCalificaciones();
    },);

    if (error) {
        return <div>{error}</div>;
    }

    if (loading) {
        return <div>Cargando calificaciones...</div>;
    }

    return (
        <div>
            <h1>Calificaciones de los Doctores</h1>
            <div className="table-container">
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>Nombre Usuario</th>
                            <th>Apellido Usuario</th>
                            <th>Teléfono Usuario</th>
                            <th>Correo Usuario</th>
                            <th>Cédula Doctor</th>
                            <th>Género Doctor</th>
                            <th>Link del Doctor</th>
                            <th>Calificación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {calificaciones.map((calificacion) => (
                            <tr key={calificacion.idDoc}>
                                <td>{calificacion.nombreUs}</td>
                                <td>{calificacion.apellidoUs}</td>
                                <td>{calificacion.telefonoUs}</td>
                                <td>{calificacion.correoUs}</td>
                                <td>{calificacion.cedDoc}</td>
                                <td>{calificacion.genDoc}</td>
                                <td><a href={calificacion.linkDoc} target="_blank" rel="noopener noreferrer">Ver Perfil</a></td>
                                <td>{calificacion.calificacionDoc}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Calificaciones;
