import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import "../estilos/inicio.css";
import "../estilos/carrusel.css";
import Acercade from './Acercade';
import Servicios from './Servicios';
import Contacto from './Contacto';
import RegistroHospital from '../RegistroHospital';
import { ToastContainer } from 'react-toastify';

function Inicio() {
  //eslint-disable-next-line
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const wrapperRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const options = {
      root: null,
      threshold: [0.1, 0.5],
      rootMargin: '-10% 0px -10% 0px'
    };

    let timeoutId;
    const handleScroll = () => {
      setIsScrolling(true);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsScrolling(false);
      }, 150);
    };

    const observer = new IntersectionObserver((entries) => {
      if (!isScrolling) {
        entries.forEach(entry => {
          if (entry.intersectionRatio > 0.1) {
            const pageNum = parseInt(entry.target.getAttribute('data-page'));
            setCurrentPage(pageNum);
            entry.target.classList.add('visible');
          }
        });
      }
    }, options);

    document.querySelectorAll('.page').forEach(page => {
      observer.observe(page);
    });

    wrapper.addEventListener('scroll', handleScroll);

    return () => {
      observer.disconnect();
      wrapper.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, [isScrolling]);

  const handlePageClick = (pageNum) => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      const targetPage = document.querySelector(`[data-page="${pageNum}"]`);
      if (targetPage) {
        targetPage.scrollIntoView({ behavior: 'smooth' });
        setCurrentPage(pageNum);
      }
    }
  };

  return (
    <>
      <div className="buttons-container" style={{ position: 'fixed', top: '20px', right: '20px', zIndex: 1000 }}>
        <Link to="/AuthContainer">
          <button className="buttonHeader">
            Iniciar Sesión
          </button>
        </Link>
      </div>

      <div className="wrapper" ref={wrapperRef}>
        <div className={`page ${currentPage === 1 ? 'visible' : ''}`} data-page="1">
          <div style={{
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '0 20px 40px',
            textAlign: 'left'
          }}>
            <div style={{
              marginBottom: '3rem'
            }}>
              <h2 style={{
                fontSize: 'clamp(2.5rem, 5vw, 4rem)',
                fontWeight: '800',
                color: 'white',
                lineHeight: '1.1',
                marginBottom: '1rem',
                letterSpacing: '-0.02em'
              }}>
                BIENVENIDO A
                <span style={{
                  display: 'block',
                  fontSize: 'clamp(3rem, 6vw, 4.5rem)',
                  background: 'linear-gradient(90deg, #fff 0%, #7fd3ed 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  letterSpacing: '-0.02em'
                }}>
                  UNA MEJOR EXPERIENCIA
                </span>
                <span style={{
                  display: 'block',
                  color: '#7fd3ed',
                  fontSize: 'clamp(2.5rem, 5vw, 4rem)',
                }}>
                  EN SALUD
                </span>
              </h2>
            </div>

            <div style={{
              maxWidth: '800px',
              marginBottom: '4rem'
            }}>
              <p style={{
                fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                lineHeight: '1.6',
                color: 'rgba(255, 255, 255, 0.9)',
                marginBottom: '1.5rem',
                fontWeight: '400'
              }}>
                ¿Te imaginas tener más tiempo para lo que realmente importa? Con HC Folder, 
                ahora puedes gestionar fácilmente cada aspecto de tu consulta médica desde 
                un solo lugar.
              </p>
              
              <p style={{
                fontSize: 'clamp(1.2rem, 2.2vw, 1.4rem)',
                lineHeight: '1.6',
                color: '#7fd3ed',
                marginBottom: '1.5rem',
                fontWeight: '500'
              }}>
                ¡Transforma tu consulta hoy y experimenta la comodidad de una gestión
                moderna, segura y sin complicaciones!
              </p>
              
              <p style={{
                fontSize: 'clamp(1.1rem, 2vw, 1.25rem)',
                lineHeight: '1.6',
                color: 'rgba(255, 255, 255, 0.85)',
                fontWeight: '400'
              }}>
                Te ayudamos a ser más eficiente, para que puedas centrarte en lo más
                importante: <span style={{color: '#7fd3ed', fontWeight: '600'}}>
                  el cuidado de tus pacientes
                </span>.
              </p>
            </div>
          </div>
        </div>
        <RegistroHospital currentPage={currentPage} />
        <Acercade currentPage={currentPage} />
        <Servicios currentPage={currentPage} />
        <Contacto currentPage={currentPage} />
      </div>

      <div className="scroll-indicator">
        {[
          { num: 1, name: 'Inicio' },
          { num: 2, name: 'Registro Hospital' },
          { num: 3, name: 'Acerca de' },
          { num: 4, name: 'Servicios' },
          { num: 5, name: 'Contacto' },
        ].map(({ num, name }) => (
          <div
            key={num}
            className={`scroll-dot ${currentPage === num ? 'active' : ''}`}
            onClick={() => handlePageClick(num)}
            data-tooltip={name}
          />
        ))}
        <ToastContainer autoClose={2000} closeButton={false} closeOnClick pauseOnHover draggable />
      </div>
    </>
  );
}

export default Inicio;