import React, { useEffect, useState } from "react";
import { Card, CardContent, CardMedia, Typography, Button } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap"; // Usamos Bootstrap para el grid

const NoticiasDoc = () => {
  const [noticias, setNoticias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expandedId, setExpandedId] = useState(null); // Estado para controlar la expansión

  // Llamada a la API para obtener las noticias
  useEffect(() => {
    const fetchNoticias = async () => {
      try {
        const response = await fetch(
          "https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/Noticia/List"
        );
        const data = await response.json();
        setNoticias(data);
        setLoading(false);
      } catch (error) {
        console.error("Error al cargar las noticias:", error);
        setLoading(false);
      }
    };

    fetchNoticias();
  }, []);

  // Función para expandir o contraer la noticia
  const toggleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id); // Si la noticia ya está expandida, la contraemos
  };

  if (loading) {
    return <div>Cargando noticias...</div>;
  }

  return (
    <Container fluid>
      <Row>
        {noticias.map((noticia) => (
          <Col xs={12} sm={6} md={4} lg={3} key={noticia.id_noticia}>
            <Card>
            <Typography variant="h6" gutterBottom>
                  {noticia.titulo}
                </Typography>
              <CardMedia
                component="img"
                alt={noticia.titulo}
                height="200"
                image={noticia.portada}
                title={noticia.titulo}
                sx={{ objectFit: "cover" }}
              />
              <CardContent>
                <Typography variant="subtitle1" color="textSecondary" paragraph>
                  {noticia.subtitulo}
                </Typography>
                <Typography variant="body2" color="textSecondary" paragraph>
                  {noticia.texto.substring(0, 150)}...
                </Typography>
                {expandedId === noticia.id_noticia ? (
                  <>
                    <Typography variant="body2" color="textPrimary" paragraph>
                      {noticia.texto}
                    </Typography>
                    <Button onClick={() => toggleExpand(noticia.id_noticia)} variant="outlined" color="secondary">
                      Leer menos
                    </Button>
                  </>
                ) : (
                  <Button onClick={() => toggleExpand(noticia.id_noticia)} variant="outlined" color="primary">
                    Leer más
                  </Button>
                )}
              </CardContent>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default NoticiasDoc;
