import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = ({ onToggle }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleValidate = async () => {
    try {
      const response = await fetch(
        `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/validarDoc/${email}`,
        {
          method: 'GET',
          mode: 'cors',
        }
      );
      if (!response.ok) {
        throw new Error('Error en la validación');
      }
      const validation = await response.json();
      return validation.existe;
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        'https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/LoginApp/authenticate',
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            correo: email,
            password: password,
          }),
        }
      );

      if (!response.ok) {
        toast.error('Error en la autenticación');
        throw new Error('Error en la autenticación');
      }

      const data = await response.json();

      if (data.tipoUsuario !== 1) {
        localStorage.setItem('authToken', data.token);
        localStorage.setItem('usuario', email);
      }

      if (data.tipoUsuario === 2) {
        toast.success('Acceso permitido');
        const isDoctorValid = await handleValidate();
        if (isDoctorValid) {
          navigate('/Home');
        } else {
          navigate('/datosDoc');
        }
      } else if (data.tipoUsuario === 3) {
        toast.success('Acceso permitido');
        navigate('/Operador');
      } else if (data.message === 'Credenciales Inválidas.') {
        toast.error('Credenciales incorrectas');
      } else {
        toast.error('Los pacientes no tienen acceso aquí');
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="auth-form-container">
      <h2 className="auth-title">Iniciar Sesión</h2>
      <form className="auth-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="form-input"
            placeholder="Ingresa tu correo electrónico"
          />
        </div>
        <div className="form-group">
          <label>Contraseña:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="form-input"
            placeholder="Ingresa tu contraseña"
          />
        </div>
        <button type="submit" className="auth-button">
          Iniciar Sesión
        </button>
      </form>
      <div className="toggle-form">
        ¿No tienes una cuenta?{' '}
        <span onClick={onToggle} className="toggle-link">
          Regístrate aquí
        </span>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Login;