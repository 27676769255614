import React, { useState, useEffect } from "react";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const NoticiasDoc = () => {
    const [titulo, setTitulo] = useState("");
    const [subtitulo, setSubtitulo] = useState("");
    const [texto, setTexto] = useState("");
    const [imagen, setImagen] = useState(null);
    const [activo, setActivo] = useState("true");
    const [noticias, setNoticias] = useState([]);
    const [noticiaSeleccionada, setNoticiaSeleccionada] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const fetchNoticias = async () => {
        try {
            const response = await fetch("https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/NoticiaDoc/Todas");
            const data = await response.json();
            setNoticias(data);
        } catch (error) {
            console.error("Error al obtener noticias:", error);
        }
    };

    useEffect(() => {
        fetchNoticias();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("Titulo", titulo);
        formData.append("Subtitulo", subtitulo);
        formData.append("Texto", texto);
        formData.append("Imagen", imagen);
        formData.append("Activo", activo === "true");

        try {
            const response = await fetch("https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/NoticiaDoc/Alta", {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                console.log("Noticia subida con éxito");
                fetchNoticias();
                closeModal();
            } else {
                console.error("Error al subir la noticia");
            }
        } catch (error) {
            console.error("Error en la petición:", error);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
    
        if (!noticiaSeleccionada || !noticiaSeleccionada.id_noticia) {
            console.error("No se ha seleccionado una noticia para actualizar");
            return;
        }
    
        const formData = new FormData();
        formData.append("Titulo", titulo);
        formData.append("Subtitulo", subtitulo);
        formData.append("Texto", texto);
    
        if (imagen) {
            formData.append("Imagen", imagen);
        } else if (noticiaSeleccionada.portada) {
            formData.append("Portada", noticiaSeleccionada.portada);
        }
    
        formData.append("Activo", activo === "true");
    
        try {
            const response = await fetch(`https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/NoticiaDoc/Actualizar/${noticiaSeleccionada.id_noticia}`, {
                method: "PUT",
                body: formData,
            });
    
            if (response.ok) {
                console.log("Noticia actualizada con éxito");
    
                setNoticias((prevNoticias) =>
                    prevNoticias.map((noticia) =>
                        noticia.id_noticia === noticiaSeleccionada.id_noticia
                            ? { ...noticia, titulo, subtitulo, texto, activo: activo === "true", portada: noticiaSeleccionada.portada }
                            : noticia
                    )
                );
    
                closeEditModal();
            } else {
                console.error("Error al actualizar la noticia");
                const errorText = await response.text();
                console.error("Detalles del error:", errorText);
            }
        } catch (error) {
            console.error("Error en la petición:", error);
        }
    };

    const handleDelete = async (id_noticia) => {
        try {
            const response = await fetch(`https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/NoticiaDoc/Eliminar/${id_noticia}`, {
                method: "DELETE",
            });
    
            if (response.ok) {
                console.log("Noticia eliminada con éxito");
                fetchNoticias();
            } else {
                console.error("Error al eliminar la noticia");
            }
        } catch (error) {
            console.error("Error en la petición:", error);
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setTitulo("");
        setSubtitulo("");
        setTexto("");
        setImagen(null);
        setActivo("true");
    };

    const openEditModal = (noticia) => {
        setNoticiaSeleccionada(noticia);
        setTitulo(noticia.titulo);
        setSubtitulo(noticia.subtitulo);
        setTexto(noticia.texto);
        setActivo(noticia.activo ? "true" : "false");
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setNoticiaSeleccionada(null);
    };

    return (
        <div className="noticias-container">
        <Fab
            color="primary"
            aria-label="add"
            onClick={() => openModal(true)}
            style={{ position: 'fixed', bottom: 16, right: 16 }}
        >
            <AddIcon />
        </Fab>

        {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h1>Subir Noticia</h1>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label>Título</label>
                                <input
                                    type="text"
                                    value={titulo}
                                    onChange={(e) => setTitulo(e.target.value)}
                                    required
                                    className="input-field"
                                />
                            </div>

                            <div>
                                <label>Subtítulo</label>
                                <input
                                    type="text"
                                    value={subtitulo}
                                    onChange={(e) => setSubtitulo(e.target.value)}
                                    required
                                    className="input-field"
                                />
                            </div>

                            <div>
                                <label>Texto</label>
                                <textarea
                                    value={texto}
                                    onChange={(e) => setTexto(e.target.value)}
                                    required
                                    className="large-input"
                                ></textarea>
                            </div>

                            <div>
                                <label>Imagen</label>
                                <input
                                    type="file"
                                    onChange={(e) => setImagen(e.target.files[0])}
                                    required
                                    className="input-field"
                                />
                            </div>

                            <button className="modal-submit" type="submit">
                                Subir Noticia
                            </button>
                        </form>

                        <button className="modal-close" onClick={closeModal}>
                            Cerrar
                        </button>
                    </div>
                </div>
            )}

            {isEditModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h1>Editar Noticia</h1>
                        <form onSubmit={handleUpdate}>
                            <div>
                                <label>Título</label>
                                <input
                                    type="text"
                                    value={titulo}
                                    onChange={(e) => setTitulo(e.target.value)}
                                    required
                                    className="input-field"
                                />
                            </div>

                            <div>
                                <label>Subtítulo</label>
                                <input
                                    type="text"
                                    value={subtitulo}
                                    onChange={(e) => setSubtitulo(e.target.value)}
                                    required
                                    className="input-field"
                                />
                            </div>

                            <div>
                                <label>Texto</label>
                                <textarea
                                    value={texto}
                                    onChange={(e) => setTexto(e.target.value)}
                                    required
                                    className="large-input"
                                ></textarea>
                            </div>

                            <div>
                                <label>Imagen</label>
                                <input
                                    type="file"
                                    onChange={(e) => setImagen(e.target.files[0])}
                                    className="input-field"
                                />
                            </div>

                            <div>
                                <label>Activo</label>
                                <select value={activo} onChange={(e) => setActivo(e.target.value)} className="input-field" required>
                                    <option value="true">Activo</option>
                                    <option value="false">Inactivo</option>
                                </select>
                            </div>

                            <button className="modal-submit" type="submit">
                                Actualizar Noticia
                            </button>
                        </form>

                        <button className="modal-close" onClick={closeEditModal}>
                            Cerrar
                        </button>
                    </div>
                </div>
            )}

            <div style={styles.noticiasGrid}>
                {noticias.map((noticia) => (
                    <div key={noticia.id_noticia} style={styles.noticiaContainer}>
                        <h2 style={styles.title}>{noticia.titulo}</h2>
                        <h4 style={styles.subtitle}>{noticia.subtitulo}</h4>
                        <img src={noticia.portada} alt="Imagen" style={styles.portada} />
                        <p style={styles.text}>{noticia.texto}</p>
                        <div style={styles.buttonContainer}>
                            <button style={styles.editButton} onClick={() => openEditModal(noticia)}>Editar</button>
                            <button style={styles.deleteButton} onClick={() => handleDelete(noticia.id_noticia)}>
                                Eliminar
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const styles = {
    noticiasGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)", 
        gap: "20px", 
        maxWidth: "800px", 
        margin: "0 auto" 
    },
    noticiaContainer: {
        padding: "15px",
        border: "1px solid #ddd",
        backgroundColor: "#f9f9f9",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "justify"
    },
    portada: {
        maxWidth: "100%",
        height: "120px",
        objectFit: "cover",
        borderRadius: "5px",
        marginBottom: "10px"
    },
    title: {
        fontSize: "16px",
        fontWeight: "bold",
        marginBottom: "5px"
    },
    subtitle: {
        fontSize: "14px",
        color: "#555",
        marginBottom: "10px"
    },
    text: {
        fontSize: "12px",
        color: "#333",
        marginBottom: "15px"
    },
    buttonContainer: {
        display: "flex",
        gap: "10px",
        marginTop: "10px"
    },
    editButton: {
        padding: "5px 10px",
        backgroundColor: "#4CAF50",
        color: "white",
        border: "none",
        borderRadius: "3px",
        cursor: "pointer"
    },
    deleteButton: {
        padding: "5px 10px",
        backgroundColor: "red",
        color: "white",
        border: "none",
        borderRadius: "3px",
        cursor: "pointer"
    },
    altaButtonContainer: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px"
    },
    altaButton: {
        padding: "10px 20px",
        fontSize: "16px",
        backgroundColor: "#007BFF",
        color: "white",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
        transition: "background-color 0.3s",
        width: "40%"
    },
    altaButtonHover: {
        backgroundColor: "#0056b3"
    }
};

export default NoticiasDoc;