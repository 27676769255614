import React, { useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import Doctores from "./Doctores";
import ValidarDoctor from "./ValidarDoc";

const MenuDoctor = () => {
    const [view, setView] = useState("generales");

    const handleChange = (event, newValue) => {
        setView(newValue);
    };

    return (
        <Box sx={{ width: "100%", typography: "body1"}}>
            <h1 style={{ textAlign: "center" }}>Doctores</h1>
            <Tabs
                value={view}
                onChange={handleChange}
                centered
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab value="doctores" label="Gestion Doctores" />
                <Tab value="validar" label="Validar Identidad" />
            </Tabs>

            <Box sx={{ mt: 2 }}>
                {view === "doctores" && <Doctores />}
                {view === "validar" && <ValidarDoctor />}
            </Box>
        </Box>
    );
};

export default MenuDoctor;
