import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ValidarDoctor = () => {
    const [documentos, setDocumentos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [documentoSeleccionado, setDocumentoSeleccionado] = useState(null);
    const [mensajeActivar] = useState("");


    useEffect(() => {
        const fetchDocumentos = async () => {
            try {
                const response = await fetch(
                    "https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/validaciones"
                );
                if (!response.ok) {
                    throw new Error(`Error al obtener los datos: ${response.statusText}`);
                }
                const data = await response.json();
                setDocumentos(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchDocumentos();
    }, []);

    const activarUsuario = async (idUsuario) => {
        try {
            const response = await fetch(
                `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Doctor/api/Autorizar/${idUsuario}`,
                {
                    method: "GET",
                }
            );

            if (!response.ok) {
                throw new Error(`Error al activar usuario: ${response.statusText}`);
            }

            toast.success(`Usuario ${idUsuario} activado exitosamente.`);
        } catch (err) {
            toast.error(`Error al activar usuario: ${err.message}`);
        }
    };

    const eliminarDocumentos = async (idUsuario) => {
        try {
            const response = await fetch(
                `https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/BorrarDocumentos/${idUsuario}`,
                {
                    method: "DELETE",
                }
            );

            if (!response.ok) {
                throw new Error(`Error al eliminar documentos: ${response.statusText}`);
            }

            toast.success(`Documentos del usuario ${idUsuario} eliminados exitosamente.`);
            // Refrescar la lista después de eliminar
            setDocumentos((prevDocumentos) =>
                prevDocumentos.filter((doc) => doc.id_usuario !== idUsuario)
            );
        } catch (err) {
            toast.error(`Error al eliminar documentos: ${err.message}`);
        }
    };

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            {mensajeActivar && <p style={{ color: "green" }}>{mensajeActivar}</p>}
            <div style={{ display: "flex", gap: "20px" }}>
                {/* Tabla de documentos */}
                <div style={{ width: "50%" }}>
                    <table border="1" style={{ width: "100%", textAlign: "left", borderCollapse: "collapse" }}>
                        <thead>
                            <tr>
                                <th>Usuario</th>
                                <th>Cédula</th>
                                <th>INE</th>
                                <th>CURP</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {documentos.map((documento) => (
                                <tr key={documento.id_usuario}>
                                    <td>{documento.id_usuario}</td>
                                    <td>
                                        <button
                                            onClick={() =>
                                                setDocumentoSeleccionado(
                                                    `https://docs.google.com/viewer?url=${encodeURIComponent(documento.rutaCedula)}&embedded=true`
                                                )
                                            }
                                        >
                                            Ver Cédula
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            onClick={() =>
                                                setDocumentoSeleccionado(
                                                    `https://docs.google.com/viewer?url=${encodeURIComponent(documento.rutaIne)}&embedded=true`
                                                )
                                            }
                                        >
                                            Ver INE
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            onClick={() =>
                                                setDocumentoSeleccionado(
                                                    `https://docs.google.com/viewer?url=${encodeURIComponent(documento.rutaCurp)}&embedded=true`
                                                )
                                            }
                                        >
                                            Ver CURP
                                        </button>
                                    </td>
                                    <td>
                                        <button onClick={() => activarUsuario(documento.id_usuario)}>
                                            Activar
                                        </button>
                                        <button
                                            style={{ marginLeft: "10px" }}
                                            onClick={() => eliminarDocumentos(documento.id_usuario)}
                                        >
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Área de visualización */}
                <div style={{ width: "50%", border: "1px solid #ccc", padding: "10px" }}>
                    {documentoSeleccionado ? (
                        <iframe
                            src={documentoSeleccionado}
                            style={{ width: "100%", height: "500px", border: "none" }}
                            title="Documento"
                        ></iframe>
                    ) : (
                        <p>Seleccione un documento para visualizarlo.</p>
                    )}
                </div>
            </div>
            <ToastContainer autoClose={2000} closeButton={false} closeOnClick pauseOnHover draggable className="custom-toast" />
        </div>
    );
};

export default ValidarDoctor;
