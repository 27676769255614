import React, { useEffect, useState, useCallback } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Switch from '@mui/material/Switch';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import L from 'leaflet';
import './userstyles.css'; 
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const Hospitales = () => {
    const [hospitales, setHospitales] = useState([]); 
    const [loading, setLoading] = useState(true); 
    const [searchTerm, setSearchTerm] = useState(''); 
    const [error, setError] = useState(null); 
    const [selectedHos, setSelectedHos] = useState(null);
    const [modalState, setModalState] = useState({ isOpen: false, isAltaOpen: false });
    const [newHospital, setNewHospital] = useState(initialHospitalState());

    function initialHospitalState() {
        return {
            nombreHos: '',
            telefonoHos: '',
            correoHos: '',
            direccionHos: '',
            longitudHos: '',
            latitudHos: '',
            imagenHos: '',
            especialHos: 0,
            informacionHos: '',
        };
    }

    const handleImageUpload = useCallback(async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        
        try {
            const response = await fetch("https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/api/Uploadhospital/simpleupload", {
                method: "POST",
                body: formData
            });

            if (!response.ok) throw new Error("Error al subir la imagen");

            const data = await response.json();
            setNewHospital(prev => ({ ...prev, imagenHos: data.url.toString() }));
        } catch (error) {
            setError("Error al subir la imagen: " + error.message);
        }
    }, []);

    const handleDrop = useCallback((event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) handleImageUpload(file);
    }, [handleImageUpload]);

    const fetchHospitales = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch("https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Hospital/api/Todos");
            if (!response.ok) throw new Error("Error al obtener los hospitales");
            const data = await response.json();
            setHospitales(data);
        } catch (err) {
            setError("Hubo un error al obtener los datos: " + err.message);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchHospitales();
    }, [fetchHospitales]);

    const altaHospital = async () => {
        try {
            await fetch("https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Hospital/api/Alta", {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newHospital),
            });
            closeModalAlta();
            fetchHospitales();
            toast.success("Hospital agregado con éxito!");
        } catch (err) {
            setError("Error al dar de alta el hospital: " + err.message);
            toast.error("Error al dar de alta el hospital.");
        }
    };    

    const actualizarHospital = async () => {
        try {
            await fetch("https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Hospital/api/Actualizar", {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(selectedHos),
            });
            closeModal();
            fetchHospitales();
            toast.success("Hospital actualizado con éxito!");
        } catch (err) {
            setError("Error al actualizar el hospital: " + err.message);
            toast.error("Error al actualizar el hospital.");
        }
    };    
    
    const eliminarHospital = async (idHos) => {
        try {
            await fetch(`https://hcfolderbacknet-ajgqcgdhabhuhyfs.mexicocentral-01.azurewebsites.net/Hospital/api/Desactivar/${idHos}`, {
                method: 'PUT',
            });
            closeModal();
            fetchHospitales();
            toast.success("Hospital eliminado con éxito!");
        } catch (err) {
            setError("Error al eliminar el hospital: " + err.message);
            toast.error("Error al eliminar el hospital.");
        }
    };    
    

    const handleRowClick = (hospital) => {
        setSelectedHos(hospital);
        setModalState({ ...modalState, isOpen: true });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedHos(prev => ({ ...prev, [name]: value }));
    };

    const handleNewInputChange = (e) => {
        const { name, value } = e.target;
        setNewHospital(prev => ({ ...prev, [name]: value }));
    };

    const closeModal = () => setModalState({ ...modalState, isOpen: false });

    const closeModalAlta = () => {
        setModalState({ ...modalState, isAltaOpen: false });
        setNewHospital(initialHospitalState());
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredHospitales = hospitales.filter(hospital =>
        hospital.nombreHos.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (error) {
        toast.error(error);
        return <div>{error}</div>;
    }
    
    if (loading) return <div style={{ textAlign: 'center', padding: '50px' }}><div className="spinner"></div><p>Cargando hospitales...</p></div>;

    return (
        <div>
            <h1>Lista de Hospitales</h1>
            <Fab
                color="primary"
                aria-label="add"
                onClick={() => setModalState({ ...modalState, isAltaOpen: true })}
                style={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <AddIcon />
            </Fab>
            <input
                type="text"
                placeholder="Buscar por nombre..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-input"
            />
            <div className="table-container">
                <table className="styled-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Teléfono</th>
                            <th>Correo</th>
                            <th>Dirección</th>
                            <th>Status</th>
                            <th>Especialidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredHospitales.length === 0 ? (
                            <tr>
                                <td colSpan="7">No se encontraron hospitales que coincidan con la búsqueda.</td>
                            </tr>
                        ) : (
                            filteredHospitales.map((hospital) => (
                                <tr key={hospital.idHos} onClick={() => handleRowClick(hospital)}>
                                    <td>{hospital.idHos}</td>
                                    <td>{hospital.nombreHos}</td>
                                    <td>{hospital.telefonoHos}</td>
                                    <td>{hospital.correoHos}</td>
                                    <td>{hospital.direccionHos}</td>
                                    <td>{hospital.statusHos === 1 ? "Activo" : "Inactivo"}</td>
                                    <td>{hospital.especialHos === 1 ? "Sí" : "No"}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
    

            {/* Modal para editar hospital */}
            {modalState.isOpen && selectedHos && (
                <div className="modal">
                    <div className="modal-content">
                        <h2 className="titulos-no-move">Editar Hospital</h2>
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                actualizarHospital();
                            }}
                        >
                            <label>ID del Hospital:</label>
                            <input
                                type="text"
                                name="idHos"
                                value={selectedHos.idHos}
                                onChange={handleInputChange}
                                readOnly
                            />

                            <label>Nombre:</label>
                            <input
                                type="text"
                                name="nombreHos"
                                value={selectedHos.nombreHos}
                                onChange={handleInputChange}
                                required
                            />

                            <label>Teléfono:</label>
                            <input
                                type="text"
                                name="telefonoHos"
                                value={selectedHos.telefonoHos}
                                onChange={handleInputChange}
                                pattern="^\d{10}$"
                                title="Ingrese un número de 10 dígitos"
                                required
                            />

                            <label>Correo:</label>
                            <input
                                type="email"
                                name="correoHos"
                                value={selectedHos.correoHos}
                                onChange={handleInputChange}
                                required
                            />

                            <label>Dirección:</label>
                            <input
                                type="text"
                                name="direccionHos"
                                value={selectedHos.direccionHos}
                                onChange={handleInputChange}
                                required
                            />

                            <label>Status:</label>
                            <Switch
                                checked={selectedHos.statusHos === 1}
                                onChange={(e) =>
                                    handleInputChange({
                                        target: {
                                            name: "statusHos",
                                            value: e.target.checked ? 1 : 0,
                                        },
                                    })
                                }
                            />

                            <label>Imagen:</label>
                            <div>
                                <label htmlFor="file-upload-edit" style={{ display: 'block', marginBottom: '10px' }}>
                                    Arrastra y suelta una imagen aquí o haz clic para seleccionar
                                </label>
                                <div
                                    onDrop={handleDrop}
                                    onDragOver={(e) => e.preventDefault()}
                                    style={{
                                        border: '2px dashed #007bff',
                                        borderRadius: '5px',
                                        padding: '20px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s',
                                    }}
                                    onClick={() => document.getElementById('file-upload-edit').click()}
                                >
                                    {selectedHos.imagenHos ? (
                                        <p>{selectedHos.imagenHos.split('/').pop()}</p>
                                    ) : (
                                        <p>Arrastra tu imagen aquí</p>
                                    )}
                                </div>
                                <input
                                    id="file-upload-edit"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageUpload(e.target.files[0])}
                                    style={{ display: 'none' }}
                                />
                            </div>

                            <label>Hospital de especialidad:</label>
                            <Switch
                                checked={selectedHos.especialHos === 1}
                                onChange={(e) =>
                                    handleInputChange({
                                        target: {
                                            name: "especialHos",
                                            value: e.target.checked ? 1 : 0,
                                        },
                                    })
                                }
                            />

                            <label>Información:</label>
                            <textarea
                                name="informacionHos"
                                className="large-input"
                                value={selectedHos.informacionHos}
                                onChange={handleInputChange}
                            />

                            <div className="modal-buttons">
                                <button type="submit" className="modal-submit">Guardar Cambios</button>
                                <button style={{ backgroundColor: '#960000' }} type="button" className="modal-close" onClick={() => eliminarHospital(selectedHos.idHos)}>
                                    Eliminar
                                </button>
                                <button type="button" className="modal-close" onClick={closeModal}>Cerrar</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Modal para dar de alta hospital */}
            {modalState.isAltaOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Agregar Hospital</h2>
                        <form onSubmit={(e) => { e.preventDefault(); altaHospital(); }}>
                            <label>Nombre:</label>
                            <input
                                type="text"
                                name="nombreHos"
                                value={newHospital.nombreHos}
                                onChange={handleNewInputChange}
                                required
                            />
    
                            <label>Teléfono:</label>
                            <input
                                type="text"
                                name="telefonoHos"
                                value={newHospital.telefonoHos}
                                onChange={handleNewInputChange}
                                pattern="^\d{10}$"
                                title="Ingrese un número de 10 dígitos"
                                required
                            />
    
                            <label>Correo:</label>
                            <input
                                type="email"
                                name="correoHos"
                                value={newHospital.correoHos}
                                onChange={handleNewInputChange}
                                required
                            />
    
                            <label>Dirección:</label>
                            <input
                                type="text"
                                name="direccionHos"
                                value={newHospital.direccionHos}
                                onChange={handleNewInputChange}
                                required
                            />
    
                            <label>Imagen:</label>
                            <div>
                                <label htmlFor="file-upload" style={{ display: 'block', marginBottom: '10px' }}>
                                    Arrastra y suelta una imagen aquí o haz clic para seleccionar
                                </label>
                                <div
                                    onDrop={handleDrop}
                                    onDragOver={(e) => e.preventDefault()}
                                    style={{
                                        border: '2px dashed #007bff',
                                        borderRadius: '5px',
                                        padding: '20px',
                                        textAlign: 'center',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s',
                                    }}
                                    onClick={() => document.getElementById('file-upload').click()}
                                >
                                    {newHospital.imagenHos ? (
                                        <p>{newHospital.imagenHos.split('/').pop()}</p>
                                    ) : (
                                        <p>Arrastra tu imagen aquí</p>
                                    )}
                                </div>
                                <input
                                    id="file-upload"
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageUpload(e.target.files[0])}
                                    style={{ display: 'none' }}
                                />
                            </div>
    
                            <label>Hospital de Especialidad:</label>
                            <Switch
                                checked={newHospital.especialHos === 1}
                                onChange={(e) =>
                                    handleNewInputChange({
                                        target: {
                                            name: "especialHos",
                                            value: e.target.checked ? 1 : 0,
                                        },
                                    })
                                }
                            />
    
                            <label>Información:</label>
                            <textarea
                                name="informacionHos"
                                className="large-input"
                                value={newHospital.informacionHos}
                                onChange={handleNewInputChange}
                            />
    
                            <button type="submit">Agregar Hospital</button>
                        </form>
                        <button onClick={closeModalAlta}>Cerrar</button>
                    </div>
                </div>
            )}
            <MapContainer center={[19.4326, -99.1332]} zoom={5} style={{ height: '400px', width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                {hospitales.map((hospital) => (
                    <Marker
                        key={hospital.idHos}
                        position={[hospital.latitudHos, hospital.longitudHos]}
                    >
                        <Popup>
                            <strong>{hospital.nombreHos}</strong><br />
                            {hospital.telefonoHos}<br />
                            {hospital.correoHos}
                        </Popup>
                    </Marker>
                ))}
            </MapContainer>
            <ToastContainer autoClose={2000} closeButton={false} closeOnClick pauseOnHover draggable className="custom-toast" />
        </div>
    );
};

export default Hospitales;